<!-- <ngx-spinner>
    <p class="loading" style="color:white; margin-top: 6.5rem;">Cargando...</p>
</ngx-spinner>
<ngx-loading-bar></ngx-loading-bar>
<router-outlet></router-outlet>
<app-tap-to-top></app-tap-to-top>
<app-layout-box></app-layout-box> -->

<ng-container *ngIf="visualizarApp == true">
    <ngx-spinner>
        <p class="loading" style="color:white; margin-top: 6.5rem;">Cargando...</p>
    </ngx-spinner>
    <ngx-loading-bar></ngx-loading-bar>
    <router-outlet></router-outlet>
    <app-layout-box></app-layout-box>
</ng-container>