import { Component, OnInit, Injectable, PLATFORM_ID, Inject, ElementRef, ViewChild } from '@angular/core';
import { DecimalPipe, isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
//import { TranslateService } from '@ngx-translate/core';
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { basicAlert, timerOut } from 'src/app/util/utils';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ArticuloModel } from 'src/app/models/Articulo.model';

declare let $: any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  products: ArticuloModel[] = []
  show: boolean = false;
  filtro: string = '';
  moneda: any = {};
  viewERP: boolean = false;
  companiaInfo: any = {};

  public languages = [{
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }];

  @ViewChild('busqueda') busqueda: ElementRef;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    //private translate: TranslateService,
    private router: Router,
    private decimalPipe: DecimalPipe,
    private spinnerService: SpinnerService,
    public productService: ProductService) {

    this.productService.cartItems.subscribe(response => this.products = response);
    this.companiaInfo = JSON.parse(localStorage.getItem("companiaInfo"));

    let vMoneda = JSON.parse(localStorage.getItem("moneda_compania"));
    if (vMoneda != null)
      this.moneda = vMoneda;


  }

  ngOnInit(): void {
    if ((this.companiaInfo.alias_url == 'tsicommerce') || (this.companiaInfo.alias_url == 'web') || (this.companiaInfo.alias_url == 'localhost')) {
      this.viewERP = true;
    } else {
      this.viewERP = false;
    }
  }

  changeLanguage(code) {
    if (isPlatformBrowser(this.platformId)) {
      // this.translate.use(code)
    }
  }

  get getTotal() {
    let total: any;
    let totalDecimal: any;

    this.productService.cartTotalAmount().subscribe((monto: number) => {
      total = this.decimalPipe.transform(monto, "0.2-2")
      totalDecimal = total;
    })
    return totalDecimal;
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }

  public openSearch() {
    this.show = true;
    timerOut(100).then((value: any) => {
      if (value) { this.busqueda.nativeElement.focus(); }
    });
  }

  public closeSearch() {
    this.show = false;
  }

  filtroArticulo() {
    console.log(this.filtro)
    if (this.filtro.length <= 1) {
      basicAlert('Debe ingresar más de un caracter para realizar la búsqueda', 'warning', 'Aceptar');
      timerOut(100).then((value: any) => {
        if (value) { this.busqueda.nativeElement.focus(); }
      });
    } else {
      let busqueda: string = '%' + this.filtro.replace(' ', '%').replace(' ', '%').replace(' ', '%').replace(' ', '%').replace(' ', '%').replace(' ', '%').replace(' ', '%').replace(' ', '%').replace(' ', '%').replace(' ', '%') + '%';
      this.spinnerService.activarLoading(true)

      localStorage.setItem("busqueda", 'por descripcion');
      if (window.location.href.includes('buscar/articulos/')) {
        this.router.navigateByUrl('/DummyComponent', { skipLocationChange: true }).then(() =>
          this.router.navigate(['/ecommerce/shop/buscar/articulos'], { queryParams: { busqueda: busqueda, clase: '', codigo: '', seccion: '', preciomax: '', preciomin: '', sortBy: '' } }));
        this.show = false;
        this.filtro = '';
      } else {
        this.router.navigate(['/ecommerce/shop/buscar/articulos'], { queryParams: { busqueda: busqueda, clase: '', codigo: '', seccion: '', preciomax: '', preciomin: '', sortBy: '' } });
        this.show = false;
        this.filtro = '';
      }
    }
  }
}