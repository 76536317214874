import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalcularMontosService {

  constructor(private decimalPipe: DecimalPipe) { }

  calcular_precio_total_desc1(data: any) {

    let totalFormat: any;

    let totalDsc1: number = 0.0;
    let totaNeto: number = 0.0;

    totalDsc1 = data.precio * ((100 - data.desc01) / 100);

    // totalFormat = this.decimalPipe.transform(totalDsc1, "0.2-2");
    // totaNeto = parseFloat(totalFormat);

    totaNeto = totalDsc1;

    return totaNeto;
  }

  calcular_precio_total_desc2(data: any) {

    let totalFormat: any;

    let totalDsc1: number = 0.0;
    let totalDsc2: number = 0.0;

    let totaNeto: number = 0.0;

    totalDsc1 = data.precio * ((100 - data.desc01) / 100);
    totalDsc2 = totalDsc1 * ((100 - data.desc02) / 100);

    // totalFormat = this.decimalPipe.transform(totalDsc2, "0.2-2");
    // totaNeto = parseFloat(totalFormat);

    totaNeto = totalDsc2;

    return totaNeto;
  }

  calcular_precio_total_desc3(data: any) {

    let totalFormat: any;

    let totalDsc1: number = 0.0;
    let totalDsc2: number = 0.0;
    let totalDsc3: number = 0.0;

    let totaNeto: number = 0.0;

    totalDsc1 = data.precio * ((100 - data.desc01) / 100);
    totalDsc2 = totalDsc1 * ((100 - data.desc02) / 100);
    totalDsc3 = totalDsc2 * ((100 - data.desc03) / 100);

    // totalFormat = this.decimalPipe.transform(totalDsc3, "0.2-2");
    // totaNeto = parseFloat(totalFormat);

    totaNeto = totalDsc3;

    return totaNeto;
  }

  calcular_precio_total_desc4(data: any) {

    let totalFormat: any;

    let totalDsc1: number = 0.0;
    let totalDsc2: number = 0.0;
    let totalDsc3: number = 0.0;
    let totalDsc4: number = 0.0;

    let totaNeto: number = 0.0;

    totalDsc1 = data.precio * ((100 - data.desc01) / 100);
    totalDsc2 = totalDsc1 * ((100 - data.desc02) / 100);
    totalDsc3 = totalDsc2 * ((100 - data.desc03) / 100);
    totalDsc4 = totalDsc3 * ((100 - data.desc04) / 100);

    // totalFormat = this.decimalPipe.transform(totalDsc4, "0.2-2");
    // totaNeto = parseFloat(totalFormat);

    totaNeto = totalDsc4;

    return totaNeto;
  }

  calcular_precio_total_cliente_desc1(data: any) {
    let totalFormat: any;
    let totalDsc1: number = 0.0;

    let totaNeto: number = 0.0;

    totalDsc1 = data.precio_cliente * ((100 - data.desc01_cliente) / 100);

    // totalFormat = this.decimalPipe.transform(totalDsc1, "0.2-2");
    // totaNeto = parseFloat(totalFormat);

    totaNeto = totalDsc1;

    return totaNeto;
  }

  calcular_precio_total_cliente_desc2(data: any) {
    let totalFormat: any;
    let totalDsc1: number = 0.0;
    let totalDsc2: number = 0.0;
    let totaNeto: number = 0.0;

    totalDsc1 = data.precio_cliente * ((100 - data.desc01_cliente) / 100);
    totalDsc2 = totalDsc1 * ((100 - data.desc02_cliente) / 100);

    // totalFormat = this.decimalPipe.transform(totalDsc2, "0.2-2");
    // totaNeto = parseFloat(totalFormat);

    totaNeto = totalDsc2;

    return totaNeto;
  }

  calcular_precio_total_cliente_desc3(data: any) {
    let totalFormat: any;
    let totalDsc1: number = 0.0;
    let totalDsc2: number = 0.0;
    let totalDsc3: number = 0.0;

    let totaNeto: number = 0.0;

    totalDsc1 = data.precio_cliente * ((100 - data.desc01_cliente) / 100);
    totalDsc2 = totalDsc1 * ((100 - data.desc02_cliente) / 100);
    totalDsc3 = totalDsc2 * ((100 - data.desc03_cliente) / 100);

    // totalFormat = this.decimalPipe.transform(totalDsc3, "0.2-2");
    // totaNeto = parseFloat(totalFormat);

    totaNeto = totalDsc3;

    return totaNeto;
  }

  calcular_precio_total_cliente_desc4(data: any) {
    let totalFormat: any;
    let totalDsc1: number = 0.0;
    let totalDsc2: number = 0.0;
    let totalDsc3: number = 0.0;
    let totalDsc4: number = 0.0;

    let totaNeto: number = 0.0;

    totalDsc1 = data.precio_cliente * ((100 - data.desc01_cliente) / 100);
    totalDsc2 = totalDsc1 * ((100 - data.desc02_cliente) / 100);
    totalDsc3 = totalDsc2 * ((100 - data.desc03_cliente) / 100);
    totalDsc4 = totalDsc3 * ((100 - data.desc04_cliente) / 100);

    // totalFormat = this.decimalPipe.transform(totalDsc4, "0.2-2");
    // totaNeto = parseFloat(totalFormat);

    totaNeto = totalDsc4;

    return totaNeto;
  }

  calcular_desc_total(data: any) {
    let totalFormat: any;
    let totalDsc1: number = 0;
    let totalDsc2: number = 0;
    let totalDsc3: number = 0;
    let totalDsc4: number = 0;

    let totaNeto: number = 0;
    let totaNeto2: number = 0;

    totalDsc1 = 1 * ((100 - data.desc01) / 100);
    totalDsc2 = totalDsc1 * ((100 - data.desc02) / 100);
    totalDsc3 = totalDsc2 * ((100 - data.desc03) / 100);
    totalDsc4 = totalDsc3 * ((100 - data.desc04) / 100);

    totaNeto = (1 - totalDsc4) * 100;

    // totalFormat = this.decimalPipe.transform(totaNeto, "0.2-2");
    // totaNeto2 = parseFloat(totalFormat);

    totaNeto2 = totaNeto;

    return totaNeto2;
  }

  calcular_desc_total_cliente(data: any) {
    let totalFormat: any;
    let totalDsc1: number = 0;
    let totalDsc2: number = 0;
    let totalDsc3: number = 0;
    let totalDsc4: number = 0;

    let totaNeto: number = 0;
    let totaNeto2: number = 0;

    totalDsc1 = 1 * ((100 - data.desc01_cliente) / 100);
    totalDsc2 = totalDsc1 * ((100 - data.desc02_cliente) / 100);
    totalDsc3 = totalDsc2 * ((100 - data.desc03_cliente) / 100);
    totalDsc4 = totalDsc3 * ((100 - data.desc04_cliente) / 100);
    totaNeto = (1 - totalDsc4) * 100;

    // totalFormat = this.decimalPipe.transform(totaNeto, "0.2-2");
    // totaNeto2 = parseFloat(totalFormat);

    totaNeto2 = totaNeto;

    return totaNeto2;
  }
}