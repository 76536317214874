import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LazyLoadImageModule, scrollPreset } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// Header and Footer Components
import { HeaderOneComponent } from './header/header-one/header-one.component';
import { FooterOneComponent } from './footer/footer-one/footer-one.component';
import { HeaderTwoComponent } from './header/header-two/header-two.component';
import { FooterTwoComponent } from './footer/footer-two/footer-two.component';
import { HeaderThreeComponent } from './header/header-three/header-three.component';
import { FooterThreeComponent } from './footer/footer-three/footer-three.component';
import { HeaderFourComponent } from './header/header-four/header-four.component';
import { FooterFourComponent } from './footer/footer-four/footer-four.component';

// Components
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { MenuComponent } from './components/menu/menu.component';
import { SettingsComponent } from './components/settings/settings.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ProductBoxOneComponent } from './components/product/product-box-one/product-box-one.component';
import { ProductBoxVerticalSliderComponent } from './components/product/product-box-vertical-slider/product-box-vertical-slider.component';

// Modals Components
import { QuickViewComponent } from './components/modal/quick-view/quick-view.component';
import { CartVariationComponent } from './components/modal/cart-variation/cart-variation.component';

// Skeleton Loader Components

// Layout Box
import { LayoutBoxComponent } from './components/layout-box/layout-box.component';

// Tap To Top
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';

// Pipes
import { DiscountPipe } from './pipes/discount.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { SafeVideoPipe } from './pipes/safe-video.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { UpdatePasswordComponent } from './components/modal/update-password/update-password.component';
import { SeccionesComponent } from './components/secciones/secciones.component';

import { NumberOnlyDirective } from './directive/number-only.directive';
import { DetalleOperacionComponent } from './components/modal/detalle-operacion/detalle-operacion.component';
import { OfertaInicioComponent } from './components/modal/oferta-inicio/oferta-inicio.component';

@NgModule({
  declarations: [
    HeaderOneComponent,
    FooterOneComponent,
    HeaderTwoComponent,
    FooterTwoComponent,
    HeaderThreeComponent,
    FooterThreeComponent,
    HeaderFourComponent,
    FooterFourComponent,
    LeftMenuComponent,
    MenuComponent,
    SettingsComponent,
    BreadcrumbComponent,
    CategoriesComponent,
    SeccionesComponent,
    ProductBoxOneComponent,
   
    ProductBoxVerticalSliderComponent,
  
    QuickViewComponent,
    
    CartVariationComponent,
  
    LayoutBoxComponent,
    TapToTopComponent,
    DiscountPipe,
    SortPipe,
    SafeVideoPipe,
    SafeUrlPipe,
    UpdatePasswordComponent,
    NumberOnlyDirective,
    DetalleOperacionComponent,
    OfertaInicioComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    
    LazyLoadImageModule.forRoot({
      // preset: scrollPreset // <-- tell LazyLoadImage that you want to use scrollPreset
    }),
    NgxSkeletonLoaderModule
    
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    
    LazyLoadImageModule,
    NgxSkeletonLoaderModule,
    
    HeaderOneComponent,
    FooterOneComponent,
    HeaderTwoComponent,
    FooterTwoComponent,
    HeaderThreeComponent,
    FooterThreeComponent,
    HeaderFourComponent,
    FooterFourComponent,
    BreadcrumbComponent,
    CategoriesComponent,
    SeccionesComponent,
    ProductBoxOneComponent,
   
    ProductBoxVerticalSliderComponent,
    
    QuickViewComponent,
    
    CartVariationComponent,
  
    LayoutBoxComponent,
    TapToTopComponent,
    SortPipe,
    SafeVideoPipe,
    SafeUrlPipe,
    UpdatePasswordComponent,
    NumberOnlyDirective,
    DetalleOperacionComponent,
    OfertaInicioComponent
  ],
  providers:[DecimalPipe]
})
export class SharedModule { }
