import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom } from 'rxjs/operators';
// import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { GlobalConstants } from './models/global-constants';
import { Title } from '@angular/platform-browser';
import { CustomFavicon } from './util/favicon.config';

import { RutasTipoDashboardService } from './services/rutas-tipo-dashboard.service';
import { basicAlert, changeColorEmpresa, getIdEncryptedCustomer } from './util/utils';
import { SpinnerService } from './services/spinner.service';
import { ApiRestServiceService } from './services/api-rest-service.service';


declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public alias: string = '';
  public idCia_SHA1: string = '';
  public visualizarApp: boolean = false;

  // public id_facebook: any = '';
  // public msg_messenger: any = '';
  // public colorFacebook: any = '';

  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    // translate: TranslateService,
    private router: Router,
    private apiRestServiceService: ApiRestServiceService,
    private rutastipoDashboard: RutasTipoDashboardService,
    private titleService: Title,
    private spinnerService: SpinnerService) {

    // if (isPlatformBrowser(this.platformId)) {
    //   translate.setDefaultLang('en');
    //   translate.addLangs(['en', 'fr']);
    // }

    this.alias = getIdEncryptedCustomer();

    console.log(this.alias)
    console.log("this.alias")

    this.apiRestServiceService.companygetIdCiaByAlias(this.alias).subscribe((data: any) => {
      localStorage.setItem('id_SHA', data.body.object);
      this.getInfoByIdCompania(data.body.object);
      this.getListDashboardPanel(data.body.object);
      this.getListCatalogos(data.body.object);
      this.getListDashboard(data.body.object);
      this.getListCondiciones(data.body.object);
    })
  }

  getListDashboardPanel(id_SHA1: string) {
    let vertical: any[] = [];
    let codigosPadreVertical: any[] = [];

    let horizontal: any[] = [];
    let codigosPadreHorizontal: any[] = [];

    let footer: any[] = [];
    let codigosPadreFooter: any[] = [];
    let footerObj: any = {};

    this.apiRestServiceService.dashboardgetListDashboardPanelV2(id_SHA1).subscribe((response: any) => {
      console.log(response)

      vertical = response.body.filter(c => c.tipo == 1);
      footer = response.body.filter(c => c.tipo == 2);
      horizontal = response.body.filter(c => c.tipo == 3);

      vertical.forEach(element => {
        element.clase.codigos.forEach(element2 => {
          codigosPadreVertical.push(element2);
        });
      });

      horizontal.forEach(element => {
        element.clase.codigos.forEach(element2 => {
          codigosPadreHorizontal.push(element2);
        });
      });

      footer.forEach(element => {
        element.clase.codigos.forEach(element2 => {
          footerObj = {
            tipinv: element.clase.tipinv,
            clase: element.clase.clase,
            codigo: element2.codigo,
            descri: element2.descri,
            codigos: [element2.codigos]
          }

          codigosPadreFooter.push(footerObj);
        });
      });

      localStorage.setItem('menuSuperiorVertical', JSON.stringify(codigosPadreVertical));
      localStorage.setItem('menuSuperiorHorizontal', JSON.stringify(codigosPadreHorizontal));
      localStorage.setItem('menuFooter', JSON.stringify(codigosPadreFooter));
    },
      err => {
        let data: any[] = [];
        localStorage.setItem('menuSuperiorVertical', JSON.stringify(data));
        localStorage.setItem('menuSuperiorHorizontal', JSON.stringify(data));
        localStorage.setItem('menuFooter', JSON.stringify(data));
        console.log(err);
      });
  }


  private getInfoByIdCompania(cia_SHA1: string) {
    let titulo: string = '';
    this.apiRestServiceService.companygetInfoCompaniaByIdCiaSHA_1(cia_SHA1).subscribe((response: any) => {
      console.log(response)
      localStorage.setItem("companiaInfo", JSON.stringify(response.body));
      response.body.oracleUrlErp = `https://${response.body.url_erp}/erp/api/rest`;

      console.log(response.oracleUrlErp)
      this.apiRestServiceService.companyLogin_token_oracle(response.body.oracleUrlErp, {
        clave: btoa("OQM2022"),
        coduser: "admec",
        ruc: response.body.ruc
      }).subscribe((respOracle: any) => {
        this.spinnerService.activarLoading(false);

        response.body.oracleToken = respOracle.token;
        response.body.oracleIdCia = respOracle.id_cia;

        localStorage.setItem("companiaInfo", JSON.stringify(response.body));

        //CAMBIAR TITULO DINAMICO
        titulo = response.body.titulo_web != '' ? response.body.titulo_web : 'Ecommerce';
        this.setTitle(titulo);

        //CAMBIAR ICO DINAMICO
        // if (response.body.id_favicon != null) {
        //   this.setFaviconByUrl(`${GlobalConstants.urlService}/companias/view/image/${response.body.id_favicon}`)
        // } else {
        //   // this.ngxFaviconService.setDefaultFavicon();
        // }


      }, err => {
        console.log(err);
        basicAlert('Ha ocurrido un error al obtener token ERP', 'error', 'Aceptar');
        this.spinnerService.activarLoading(false);

      })

      // this.id_facebook = response.body.id_facebook != null ? response.body.id_facebook : '';
      // this.msg_messenger = response.body.msg_messenger != null ? response.body.msg_messenger : '';

      // if(this.id_facebook != ''){
      //   this.initFacebookService();
      // }
    },
      err => {
        console.log(err);
        basicAlert('Ha ocurrido un error al obtener la vista del E-COMMERCE', 'error', 'Aceptar');
        this.spinnerService.activarLoading(false);
      });
  }

  private getListDashboard(id_SHA1: string) {
    let id_cliente: any = '';
    let orden: string = 'desc';

    this.apiRestServiceService.dashboardObtenerDashboard(id_SHA1, id_cliente, orden).subscribe((response: any) => {
      console.log(response.body)

      localStorage.setItem("id_tipo_dashboard", response.body.id_dashboard_tipo);
      localStorage.setItem("dashboardDefault", JSON.stringify(response.body.secciones));

      response.body.secciones.forEach(element => {
        if (element.tipoDesc === 'Blog') {
          localStorage.setItem("infoBlog", JSON.stringify(element.detalle));
        }
        if (element.tipoDesc == 'Tabs') {
          localStorage.setItem("infoTabs", JSON.stringify(element.detalle));
        }
      });
      if (response.body.color_theme != null) {
        // this.changeColorEmpresa(response.body.color_theme);
        let color: string = '#ff4c3b'
        if (response.body.color_theme === 'color1') {
          color = '#ff4c3b';
        } else if (response.body.color_theme === 'color2') {
          color = '#3fdda7';
        } else if (response.body.color_theme === 'color3') {
          color = '#f0b54d';
        } else if (response.body.color_theme === 'color4') {
          color = '#e4604a';
        } else if (response.body.color_theme === 'color5') {
          color = '#d4b196';
        } else if (response.body.color_theme === 'color6') {
          color = '#866e6c';
        } else if (response.body.color_theme === 'color7') {
          color = '#cc2121';
        } else if (response.body.color_theme === 'color8') {
          color = '#dc457e';
        } else if (response.body.color_theme === 'color9') {
          color = '#6d7e87';
        } else if (response.body.color_theme === 'color10') {
          color = '#fa869b';
        } else if (response.body.color_theme === 'color11') {
          color = '#81ba00';
        } else if (response.body.color_theme === 'color12') {
          color = '#fe816d';
        }

        changeColorEmpresa(color);
        localStorage.setItem("colorDashboard", color);
      } else {
        changeColorEmpresa('#ff4c3b');
        localStorage.setItem("colorDashboard", '#ff4c3b');
      }

      // this.colorFacebook = '#ff4c3b';

      let link = window.location.href;
      console.log(link)
      if (link.includes('/ecommerce/dashboard/home')) {
        this.rutastipoDashboard.rutaTipoDashboard(response.body.id_dashboard_tipo);
      } else if (link.includes('/Login')) {
        this.rutastipoDashboard.rutaTipoDashboard(response.body.id_dashboard_tipo);
      }
      else {
        console.log('no incluye')
      }
      this.visualizarApp = true;
    },
      err => {
        console.log(err);
        this.visualizarApp = true;
        localStorage.setItem("colorDashboard", '#ff4c3b');
        this.spinnerService.activarLoading(false);
      });
  }

  private getListCatalogos(cia_SHA1: string) {
    let catalogos: any[] = [];

    this.apiRestServiceService.catalogoBuscar(cia_SHA1).subscribe((response: any) => {
      // console.log(response);

      catalogos = response.body;

      for (let index = 0; index < catalogos.length; index++) {
        const element = catalogos[index];
        if (element.id_imagen != null) {
          catalogos[index].img = `${GlobalConstants.urlService}/catalogos/public/image/${element.id_imagen}`
        }
      }
      localStorage.setItem("catalogos", JSON.stringify(catalogos));
    },
      err => {
        console.log(err);
        localStorage.removeItem("catalogos");
        this.spinnerService.activarLoading(false);
      });
  }

  private getListCondiciones(id_ciaSHA1: string) {
    this.apiRestServiceService.companygetListCondiciones(id_ciaSHA1).subscribe((response: any) => {
      console.log(response);
      localStorage.setItem("condiciones_servicio", JSON.stringify(response.body));
    }, err => {
      console.log(err);
    });
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }



  // private initFacebookService(): void {
  //   const initParams: InitParams = { xfbml: true, version: 'v7.0' };
  //   this.facebookService.init(initParams);
  // }
}
