export class ClienteModel {
    public id_cia: number = null;
    public id: number = null;
    public tident: string = '';
    public dident: string = '';
    public razon: string = '';
    public direcc: string = '';
    public telefono: string = '';
    public email: string = '';
    public nombres: string = '';
    public apellidopat: string = '';
    public apellidomat: string = '';
    public clave: string = '';
    public activo: boolean = true;
}

