<!--footer section -->
<footer [class]="class">
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>Nosotros</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<p>{{companiaInfo.observ}}</p>
					</div>
				</div>
				<div class="col" *ngIf="clases.length > 0">
					<div class="sub-title">
						<div class="footer-title">
							<h4>CLASIFICACIONES</h4>
						</div>
						<div class="footer-contant">
							<ul>
								<li *ngFor="let lst of clases" (click)="onClickFiltroCodigos(lst)"
									style="cursor: pointer;"><a>{{lst.descri}}</a></li>

								<li class="mega" id="hover-cls" style="margin-right: -1rem;">
									<a class="cursor" (click)="onClickAbrirPoliticaPrivacidad()">Política de Privacidad</a>
								</li>

							</ul>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
							<h4>SERVICIOS</h4>
						</div>
						<div class="footer-contant">
							<ul>
								<li class="mega" id="hover-cls" style="margin-right: -1rem;">
									<a class="cursor" (click)="onClickSoporte()">SOPORTE</a>
								</li>

								<li class="mega" id="hover-cls" style="margin-right: -1rem;">
									<a class="cursor" (click)="onClickIntranet()">INTRANET</a>
								</li>

								<li class="mega" id="hover-cls" style="margin-right: -1rem;">
									<a class="cursor" (click)="onClickFacturacionElectronica()">CPE - TSI</a>
								</li>

								<li class="mega" id="hover-cls" style="margin-right: -1rem;">
									<a class="cursor" (click)="onClickFacturacionElectronicaOracle()">CPE - TSI
										Oracle</a>
								</li>

								<li class="mega" id="hover-cls" style="margin-right: -1rem;">
									<a class="cursor" (click)="onClickRouterNosotros()">NOSOTROS</a>
								</li>

								<li class="mega" id="hover-cls" style="margin-right: -1rem;">
									<a class="cursor" (click)="onClickRouterReclamaciones()">LIBRO DE RECLAMACIONES</a>
								</li>

								<li class="mega" id="hover-cls" style="margin-right: -1rem;">
									<a class="cursor" (click)="onClickAbrirTsiOraclePrueba()">TSI Suite - PRUEBA</a>
								</li>








							</ul>
						</div>
					</div>
				</div>
				<div class="col">
					<div class="sub-title">
						<div class="footer-title">
							<h4>CONTACTO</h4>
						</div>
						<div class="footer-contant">
							<ul class="contact-list">
								<li *ngFor="let lst of companiaInfo.almacenes"><i
										class="fa fa-map-marker"></i>{{lst.direcc}}</li>
								<li><i class="fa fa-phone"></i>{{companiaInfo.telefono}}</li>
								<li class="text-transform"><i
										class="fa fa-envelope-o"></i><a>{{companiaInfo.correo}}</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
				<div class="col-xl-6 col-md-6 col-sm-12">
					<div class="footer-end">
						<p><i class="fa fa-copyright" aria-hidden="true"></i> {{ today | date:'y'}} Todos los derechos
							reservados {{companiaInfo.razon}} <br> Hecho por Grupo TSI <img
								src="assets/images/logoTSI.jpg" height="22px" width="22px"></p>
					</div>
				</div>
			</div>
		</div>
	</div>
</footer>
<!--footer section end -->