import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductService } from "../../../services/product.service";
import { Product } from "../../../classes/product";
import { ArticuloModel } from 'src/app/models/Articulo.model';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-cart-variation',
  templateUrl: './cart-variation.component.html',
  styleUrls: ['./cart-variation.component.scss']
})
export class CartVariationComponent implements OnInit, OnDestroy {

  @Input() direction: string = 'right'; // Default Direction Right

  public products: ArticuloModel[] = [];

  constructor(public productService: ProductService,
    private decimalPipe: DecimalPipe) {
    this.productService.cartItems.subscribe(response => this.products = response);
  }

  ngOnInit(): void {
    this.productService.OpenCart = false;
  }

  closeCart() {
    this.productService.OpenCart = false;
  }

  get getTotal(): Observable<number> {
    let total: any;
    let totalDecimal: any;

    this.productService.cartTotalAmount().subscribe((monto: number) => {
      total = this.decimalPipe.transform(monto, "0.2-2")
      totalDecimal = total;
    })
    return totalDecimal;
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  ngOnDestroy(): void {
    this.closeCart();
  }

}
