import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClienteModel } from 'src/app/models/cliente.model';
import { ApiRestServiceService } from 'src/app/services/api-rest-service.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { basicAlert, timerOut } from 'src/app/util/utils';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

  @Input() correo: string = '';
  params = new ClienteModel();
  claveAnterior: string = '';
  confirmarClave: string = '';
  nuevaClave: string = '';
  claveLoginLocalStorage: string = '';

  @ViewChild('claveId') claveId: ElementRef;
  @ViewChild('claveNueva') claveNueva: ElementRef;
  @ViewChild('confirmar') confirmar: ElementRef;

  constructor(private modalactiveService: NgbActiveModal,
    private clienteService: ApiRestServiceService,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.claveLoginLocalStorage = localStorage.getItem("clave");

    timerOut(10).then((value: any) => {
      if (value) { this.claveId.nativeElement.focus(); }
    })
  }

  guardar(): void {
    if (this.claveAnterior === '') {
      basicAlert('Ingresar la contraseña anterior', 'warning', 'Aceptar', null, null, this.claveId);
      return;
    }

    if (this.nuevaClave == '') {
      basicAlert('Ingresar una nueva contraseña', 'warning', 'Aceptar', null, null, this.claveNueva);
      return;
    }

    if (this.confirmarClave == '') {
      basicAlert('Ingresar nuevamente la contraseña', 'warning', 'Aceptar', null, null, this.confirmar);
      return;
    }

    if (btoa(this.claveAnterior) != this.claveLoginLocalStorage) {
      basicAlert('La contraseña anterior es incorrecta', 'warning', 'Aceptar', null, null, this.confirmar);
      return;
    }

    if (this.confirmarClave != this.nuevaClave) {
      basicAlert('La contraseña es incorrecta', 'warning', 'Aceptar', null, null, this.confirmar);
      return;
    }

    this.params.email = this.correo;
    this.params.clave = btoa(this.nuevaClave);

    this.spinnerService.activarLoading(true);
    this.clienteService.clienteActualizarClave(this.params).subscribe((response: any) => {
      console.log(response);
      basicAlert('Se actualizó correctamente', 'success', 'Aceptar');
      this.cancelar();
      this.spinnerService.activarLoading(false);
    }, err => {
      if (err.status != 401) {
        basicAlert(err.error, 'warning', 'Aceptar');
        this.spinnerService.activarLoading(false);
      }
    })
  }

  cancelar(): void {
    this.modalactiveService.dismiss('canceling');
  }

  onNexFocus() {
    timerOut(10).then((value: any) => {
      if (value) { this.claveId.nativeElement.focus(); }
    })
  }
}