import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { LoginComponent } from './pages/account/login/login.component';
import { ErrorComponent } from './pages/error/error.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './services/auth-interceptor.service';

const routes: Routes = [
  { path: '', redirectTo: 'ecommerce/dashboard/home', pathMatch: 'full' },
  { path: "login", component: LoginComponent },
  { path: '404', component: ErrorComponent },
  { path: 'ecommerce/dashboard/home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'ecommerce/shop', component: ShopComponent, loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule) },
  { path: 'ecommerce/pages', component: PagesComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
  // { path: 'elements', component: ElementsComponent, loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule) },
  { path: '**', redirectTo: '404', },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      useHash: true,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled'
    }),
   
  ],
  exports: [RouterModule],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true }]
})
export class AppRoutingModule { }
