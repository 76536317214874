<section class="login-page section-b-space">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="theme-card" style="background-color: white">
                    <div class="text-center mt-3" *ngIf="companiaInfo.id_imagen_logo != null">
                        <a class="cursor" (click)="onClickRetornar()">
                            <img [src]="imagenLogo" class="img-fluid" alt=""><br><br>
                        </a>
                    </div>

                    <div class="text-center mt-3">
                        <div *ngIf="companiaInfo.id_imagen_logo == null">
                            <a (click)="onClickRetornar()">
                                <label class="text-danger label">ECOMMERCE</label><br>
                                <label class="text-danger label mb-4">{{nombreEmpresa}}</label><br>
                            </a>
                        </div>

                        <label style="font-size: 1.2rem;font-weight: bold;">Accede a tu cuenta</label>
                    </div>
                    <form class="theme-form">
                        <div class="form-group">
                            <label for="email">Correo</label>
                            <input type="text" class="form-control" #email placeholder="Correo" name="correo"
                                [(ngModel)]="correo">
                        </div>
                        <div class="form-group">
                            <label for="review">Contraseña</label>
                            <input type="password" class="form-control" id="review" placeholder="Contraseña"
                                name="password" [(ngModel)]="password">
                        </div>
                        <div class="d-flex justify-content-center">
                            <button class="btn btn-solid" (click)="onClickLogin()">Ingresar</button>
                        </div>
                    </form>

                    <div class="row">
                        <div class="col-md-12 d-flex justify-content-center mt-lg-4">
                            <a style="cursor: pointer; text-decoration-line: underline;"
                                routerLink="/ecommerce/pages/forget/password">¿Has olvidado tu contraseña?</a>

                        </div>
                        <div class="col-md-12 d-flex justify-content-center mt-lg-4">
                            <label>¿No tienes cuenta? <a style="cursor: pointer; text-decoration-line: underline;"
                                    routerLink="/ecommerce/pages/registrar">Regístrate</a></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Section ends-->