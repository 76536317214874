import Swal from 'sweetalert2'
import * as moment from "moment";
moment.locale("es-PE");

export function getIdEncryptedCustomer(): string {
  let alias: string = '';

  const url = window.location.href;

  if (url.includes('local')) {
    // alias = 'prueba';
     alias = 'web'; // GTSI
    // alias = 'nabilaweb';
    // alias = 'mqcommerce';
    // alias = 'cuboconsultores';
    // alias = 'friopan';
    // alias = 'fiamaster';
    //  alias = 'cqtsacweb';
    // alias = 'fubballweb'
    // alias = 'luxuryweb'
  } else {
    let urlsinhttp = url.split("https://")[1]
    alias = urlsinhttp.split(".")[0];
  }

  return alias;
}

export function timerOut(timer: number) {
  let promise = new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, timer);
  });
  return promise;
}


export function setConfigTable(scrollX2: boolean) {
  let config;
  return config = {
    scrollY: "300px",
    scrollX: scrollX2,
    paging: false,
    language: languageTable(),
    searching: false,

    //   // pagingType: 'full_numbers',
    // //   pageLength: 5,
    // // lengthMenu : [5, 10, 25],
    processing: true,
    // responsive:true
  };
}

export function languageTable(): any {
  let spanish_datatables
  return spanish_datatables = {
    processing: "Procesando...",
    search: "Buscar:",
    lengthMenu: "Mostrar _MENU_ elementos",
    info: "Mostrando desde _START_ al _END_ de _TOTAL_ elementos",
    infoEmpty: "Mostrando ningún elemento.",
    infoFiltered: "(filtrado _MAX_ elementos total)",
    infoPostFix: "",
    loadingRecords: "Cargando registros...",
    zeroRecords: "No se encontraron registros",
    emptyTable: "No hay datos disponibles en la tabla",
    paginate: {
      first: "Primero",
      previous: "Anterior",
      next: "Siguiente",
      last: "Último"
    },
    aria: {
      sortAscending: ": Activar para ordenar la tabla en orden ascendente",
      sortDescending: ": Activar para ordenar la tabla en orden descendente"
    }
  }

}

export function basicAlert(texto: string, icono: any, confirmButtonText: string, showCancelButton?: boolean, cancelButtonText?: string, input?: any) {
  let focus: boolean = false;
  Swal.fire({
    // title: titulo,
    allowOutsideClick: false,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    text: texto,
    icon: icono,
    confirmButtonText: confirmButtonText,
    showCancelButton: showCancelButton,
    cancelButtonText: cancelButtonText,
    preConfirm: () => {
      setTimeout(() => {
        if (input) {
          input.nativeElement.focus();
        }
      }, 300);
    }
  });
}

export function basicAlertHTML(texto: string, icono: any, confirmButtonText: string, showCancelButton?: boolean, cancelButtonText?: string, input?: any) {
  let focus: boolean = false;
  Swal.fire({
    // title: titulo,
    allowOutsideClick: false,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    html: texto,
    icon: icono,
    confirmButtonText: confirmButtonText,
    showCancelButton: showCancelButton,
    cancelButtonText: cancelButtonText,
    preConfirm: () => {
      setTimeout(() => {
        if (input) {
          input.nativeElement.focus();
        }
      }, 300);
    }
  });
}

export function alertaConfirmacion(mensaje: string, tipoAlerta: any, focusCancelar: boolean = false, focusConfirmar: boolean = false,) {
  var ejecutarAlerta = new Promise(function (resolve) {
    Swal.fire({
      text: mensaje,
      icon: tipoAlerta,
      focusCancel: focusCancelar,
      focusConfirm: focusConfirmar,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    }).then((result) => {
      return resolve(result.value);
    });
  })

  return ejecutarAlerta;
}

export function alertaRouter(texto: string, icono: any, confirmButtonText: string, showCancelButton?: boolean, cancelButtonText?: string, input?: any) {
  var ejecutarAlerta = new Promise(function (resolve) {
    Swal.fire({
      allowOutsideClick: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      text: texto,
      icon: icono,
      confirmButtonText: confirmButtonText,
      showCancelButton: showCancelButton,
      cancelButtonText: cancelButtonText,
    }).then((result) => {
      return resolve(result.value);
    });
  })

  return ejecutarAlerta;
}
//YYYY-MM-DD
export function obtenerFechaActual(formato: any) {
  return moment(new Date()).format(formato);
}

export function fechaSeleccionada(data: any, formato: any) {
  return moment(data).format(formato);
}

export function changeColorEmpresa(coloAcambiar: string) {
  return document.documentElement.style.setProperty('--theme-deafult', coloAcambiar);
}

export function viewDescuento(element: any) {
  var viewDescuento: boolean = false;
  if (element.precio_cliente === null) {
    if (element.desc01 > 0 && element.desc02 === 0 && element.desc03 === 0 && element.desc04 === 0 ||
      element.desc02 > 0 && element.desc01 === 0 && element.desc03 === 0 && element.desc04 === 0 ||
      element.desc03 > 0 && element.desc01 === 0 && element.desc02 === 0 && element.desc04 === 0 ||
      element.desc04 > 0 && element.desc01 === 0 && element.desc02 === 0 && element.desc03 === 0) {
      viewDescuento = true;
    } else {
      viewDescuento = false;
    }
  } else {
    if (element.desc01_cliente > 0 && element.desc02_cliente === 0 && element.desc03_cliente === 0 && element.desc04_cliente === 0 ||
      element.desc02_cliente > 0 && element.desc01_cliente === 0 && element.desc03_cliente === 0 && element.desc04_cliente === 0 ||
      element.desc03_cliente > 0 && element.desc01_cliente === 0 && element.desc02_cliente === 0 && element.desc04_cliente === 0 ||
      element.desc04_cliente > 0 && element.desc01_cliente === 0 && element.desc02_cliente === 0 && element.desc03_cliente === 0) {
      viewDescuento = true;
    } else {
      viewDescuento = false;
    }
  }

  return viewDescuento;
}

export function colViewDesc(element: any) {
  var viewDescuento: boolean = false;
  if (element.precio_cliente === null) {
    if (element.desc01 === 0 && element.desc02 === 0 && element.desc03 === 0 && element.desc04 === 0) {
      viewDescuento = true;
    } else {
      viewDescuento = false;
    }
  } else {
    if (element.desc01_cliente === 0 && element.desc02_cliente === 0 && element.desc03_cliente === 0 && element.desc04_cliente === 0) {
      viewDescuento = true;
    } else {
      viewDescuento = false;
    }
  }

  return viewDescuento;
}