import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalConstants } from '../models/global-constants';
import { ClienteModel } from '../models/cliente.model';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    public clienteModel = new ClienteModel();
    public token: any = '';

    constructor(private http: HttpClient, private router: Router) { }


    generarClave(id_cia: string, sender: ClienteModel) {
        return this.http.post(GlobalConstants.urlService + `/auth/cliente/${id_cia}/generar-clave`, sender);
    }

    //Login
    login(id_cia: string, ClienteObject: ClienteModel) {
        return this.http.post(`${GlobalConstants.urlService}/auth/cliente/${id_cia}/login`, ClienteObject);
    }

    estaLogueado() {
        this.cargarToken();
        return (this.token.length > 5) ? true : false;
    }

    cargarToken() {
        if (localStorage.getItem('token')) {
            this.token = localStorage.getItem('token');
        }
        else {
            this.clienteModel.email = '';
            this.router.navigate(['/Login']);
        }
    }
}
