import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalConstants } from '../models/global-constants';
import { ArticuloStockModel } from '../models/ArticuloStock.model';

@Injectable({
    providedIn: 'root'
})
export class ArticuloService {
    constructor(private http: HttpClient) { }

    //List
    getArticuloByIdCiaSHA_1(id: number, cliente: number, id_cia_oracle: number, cia_SHA1: string) {
        return this.http.get(`${GlobalConstants.urlService}/articulos/public/v2/${id}?cliente=${cliente}&id_cia_oracle=${id_cia_oracle}&cia=${cia_SHA1}`, { observe: 'response' });
    }

    getArticuloByIdModelo(code_modelo: string, cia: string) {
        return this.http.get(`${GlobalConstants.urlService}/articulos/public/v2/modelos/${code_modelo}?cia=${cia}`, { observe: 'response' });
    }


    //List   
    getFiltroArticulos(cia: string, cliente: number, codart: string, descri: string, id_seccion: string, clases: string, codigos: string, minprecio: number, maxprecio: number, limit: number, offset: number) {
        return this.http.get(`${GlobalConstants.urlService}/articulos/v2/filtrar?cia=${cia}&cliente=${cliente}&codart=${encodeURIComponent(codart)}&descri=${encodeURIComponent(descri)}&id_seccion=${encodeURIComponent(id_seccion)}&clases=${encodeURI(clases)}&codigos=${encodeURI(codigos)}&minprecio=${minprecio}&maxprecio=${maxprecio}&limit=${limit}&offset=${offset}`, { observe: 'response' });
    }

    getListArticulosRelacion(cia: string, id_articulo: number) {
        return this.http.get(`${GlobalConstants.urlService}/articulos/public/relaciondos?cia=${cia}&articulo=${id_articulo}`, { observe: 'response' });
    }

    getStockArticulo(url: string, tokenERP: string, tipinv: number, codart: string, codalm: number, codadd01: string, codadd02: string) {

        const httpOptions: any = {
            headers: new HttpHeaders({
                AuthorizationEcommerce: tokenERP
            }),
        };

        return this.http.get(`${url}/articulo/${tipinv}/${encodeURIComponent(codart)}/almacen/${codalm}/stock?codadd01=${codadd01}&codadd02=${codadd02}`, httpOptions);
    }

    getStockArticulo_v2(url: string, id_cia: number, tipinv: number, codart: string, codalm: number, codadd01: string, codadd02: string) {
        return this.http.get(`${url}/articulo/ecommerce/${id_cia}/public/${tipinv}/${encodeURIComponent(codart)}/almacen/${codalm}/stock?codadd01=${codadd01}&codadd02=${codadd02}`);
    }


    //Listar Moneda
    getListClases(cia_SHA1: string) {
        return this.http.get(`${GlobalConstants.urlService}/clase-articulos/public/buscar?cia=${cia_SHA1}`, { observe: 'response' })
    }

    getListClasesCodigo(tipinv: number, clase: number, clase_codigo_padre: string, cia_SHA1: string) {
        return this.http.get(`${GlobalConstants.urlService}/clase-articulos-codigo/public/buscar?tipinv=${tipinv}&clase=${clase}&clase_codigo_padre=${clase_codigo_padre}&cia=${cia_SHA1}`, { observe: 'response' })
    }

    updateArticuloStock(ParamsArticulo: ArticuloStockModel) {
        return this.http.put(`${GlobalConstants.urlService}/articulos/actualiza-stock`, ParamsArticulo);
    }
}