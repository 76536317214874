<header [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li style="font-size: 1.1rem;"><i class="fa fa-phone" aria-hidden="true"></i>Teléfono : <a
                  style="font-weight: bold;">{{ companiaInfo.telefono }} </a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-right">
          <ul class="header-dropdown">
            <li class="mobile-account" *ngIf="sessionInfo === null">
              <a routerLink="/login"><i class="fa fa-user"></i>Iniciar sesión</a>
            </li>

            <li class="mobile-razon-sozial" *ngIf="sessionInfo != null">
              {{empresa}} &nbsp; {{nombres}} &nbsp; &nbsp;
            </li>

            <li class="onhover-dropdown mobile-account" *ngIf="sessionInfo != null">
              <i class="fa fa-user"></i> Mi cuenta
              <ul class="onhover-show-div">
                <li (click)="onCLicMiPerfil()">
                  <a>
                    Mi perfil
                  </a>
                </li>
                <li (click)="onCLicMisPedidos()">
                  <a>
                    Mis pedidos
                  </a>
                </li>
                <li (click)="onCLicFavoritos()">
                  <a>
                    Mis favoritos
                  </a>
                </li>
                <li>
                  <a (click)="cerrar()">
                    Cerrar sesión
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container container-2">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <app-left-menu></app-left-menu>
            <div class="brand-logo">
              <!-- <a routerLink="/home/fashion" id="headerlogo"> -->
              <a (click)="onClickRetornar()" class="cursor" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
            <div>
              <app-settings></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>