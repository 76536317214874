import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-detalle-operacion',
  templateUrl: './detalle-operacion.component.html',
  styleUrls: ['./detalle-operacion.component.scss']
})
export class DetalleOperacionComponent implements OnInit {

  @Input() data: any;

  detalle: any = {};
  
  constructor(private modalactiveService: NgbActiveModal) { }

  ngOnInit(): void {
    this.detalle = {
      operacion: this.data.deposito.nropera,
      moneda: this.data.tipmon,
      importe: this.data.deposito.importe,
      fecha: this.getDate(this.data.deposito.fecha),
    }
    console.log(this.data)
  }

  cancelar(): void {
    this.modalactiveService.dismiss('canceling');
  }

  getDate(time: any) {
    if (time != null) {
      return new Date(time).toISOString().slice(0, 10)
    } else {
      return null;
    }
  }
}