import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';
import { ArticuloService } from '../../../services/articulo.service';
import { SpinnerService } from '../../../services/spinner.service';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {

  public codigosPadre: any[] = [];
  public articulos: any[] = [];
  public alias: string = '';
  public idCia_SHA1: string = '';
  public id_dashboard_tipo: number = null;
  public sessionInfo: any = {};

  constructor(private router: Router, 
    private articuloService: ArticuloService,
    private spinnerService: SpinnerService,
    public navServices: NavService) {
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });

    let codigoActiveObj: any = {};
    let codigos: any[] = [];

    codigos = JSON.parse(localStorage.getItem("menuSuperiorVertical"));

    if(codigos != null){
      codigos.forEach(element => {
        codigoActiveObj = {
          codigo: element.codigo,
          codigos: element.codigos,
          descri: element.descri,
          active: false
        }
        this.codigosPadre.push(codigoActiveObj)
      });
  
    }

    this.idCia_SHA1 = localStorage.getItem("id_SHA");
    this.sessionInfo = JSON.parse(localStorage.getItem("sessionInfo"));
  }

  ngOnInit(): void {
  }

  leftMenuToggle(): void {
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  onClickFiltroCodigos(data: any) {
    console.log(data)
    localStorage.setItem("busqueda", 'menu lateral');
    this.spinnerService.activarLoading(true);
    if (window.location.href.includes('buscar/articulos/')) {
      this.router.navigateByUrl('/DummyComponent', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/ecommerce/shop/buscar/articulos'], { queryParams: { clase: data.clase, codigo: data.codigo, menu: 'Lateral' } }));
        this.spinnerService.activarLoading(false);
    } else {
      this.router.navigate(['/ecommerce/shop/buscar/articulos'], { queryParams: { clase: data.clase, codigo: data.codigo, menu: 'Lateral' } });
    }
  }
}
