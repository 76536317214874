import { Component, OnInit } from '@angular/core';
import { RutasTipoDashboardService } from 'src/app/services/rutas-tipo-dashboard.service';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  public id_dashboard_tipo: number = null;

  constructor(private TipoDashboard: RutasTipoDashboardService,
    private spinnerService: SpinnerService) {
    // document.body.style.backgroundImage = "url('')";
  }
  ngOnInit(): void {
    this.spinnerService.activarLoading(false);
  }

  onClickRetornar() {
    this.id_dashboard_tipo = +localStorage.getItem("id_tipo_dashboard");
    console.log(this.id_dashboard_tipo)

    setTimeout(() => {
      this.TipoDashboard.rutaTipoDashboard(this.id_dashboard_tipo);
    }, 150);
  }
}
