import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { ClienteModel } from 'src/app/models/cliente.model';
import { GlobalConstants } from 'src/app/models/global-constants';
import { ApiRestServiceService } from 'src/app/services/api-rest-service.service';
import { LoginService } from 'src/app/services/login.service';
import { RutasTipoDashboardService } from 'src/app/services/rutas-tipo-dashboard.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { basicAlert, changeColorEmpresa, timerOut } from 'src/app/util/utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  correo: string = '';
  password: string = '';
  clienteModel = new ClienteModel();
  id_SHA1: string = '';
  imagenLogo: string = '';
  nombreEmpresa: string = '';
  imagenFondo: string = '';
  companiaInfo: any = {};
  id_dashboard_tipo: number = null;

  @ViewChild('email') email: ElementRef;
  constructor(private spinnerService: SpinnerService,
    private loginService: LoginService,
    private apiRestServiceService: ApiRestServiceService,
    private TipoDashboard: RutasTipoDashboardService) {

    this.spinnerService.activarLoading(true);
    this.id_dashboard_tipo = +localStorage.getItem("id_tipo_dashboard");
    this.id_SHA1 = localStorage.getItem("id_SHA");
    this.companiaInfo = JSON.parse(localStorage.getItem("companiaInfo"));

    this.nombreEmpresa = this.companiaInfo.razon;
    if (this.companiaInfo.id_imagen_logo != null) {
      this.imagenLogo = `${GlobalConstants.urlService}/companias/view/image/${this.companiaInfo.id_imagen_logo}`;
    }
    if (this.companiaInfo.id_imagen_login != null) {
      this.imagenFondo = `${GlobalConstants.urlService}/companias/view/image/${this.companiaInfo.id_imagen_login}`;

      document.body.style.backgroundImage = `url(${this.imagenFondo})`;
      // document.body.style.scale = ''
    }
  }

  ngOnInit() {
    moment.locale('es-PE');
    timerOut(100).then((value: any) => {
      if (value) { this.email.nativeElement.focus(); }
    });
    this.spinnerService.activarLoading(false);
  }


  onClickLogin() {
    this.spinnerService.activarLoading(true);
    this.clienteModel.email = this.correo;
    this.clienteModel.clave = btoa(this.password);

    console.log(this.clienteModel.clave)

    this.loginService.login(this.id_SHA1, this.clienteModel).subscribe((response: any) => {
      console.log(response);

      localStorage.setItem('nombre', response.nombres);
      localStorage.setItem('correo', response.correo);
      localStorage.setItem('token', response.token);
      localStorage.setItem('clave', this.clienteModel.clave);
      localStorage.setItem('sessionInfo', JSON.stringify(response));

      let fecInicioSesion = moment(new Date()).format("HH:mm:ss");
      localStorage.setItem('fecInicioSesion', fecInicioSesion);

      this.getByIdMoneda(this.companiaInfo.moneda_precio);
      this.getListDashboard(this.id_SHA1, response.id);
      this.getByIdCliente(response.id);
      this.getByToDay();
      this.getByIdCompaniaPayPal();
      this.getListBancos();
    }
      , err => {
        console.log(err);
        if (err.status == 404) {
          basicAlert(err.error, 'error', 'Aceptar');
        }
        if (err.status == 401) {
          basicAlert('Su sesión a expirado', 'warning', 'Aceptar');
        }
        if (err.status == 400) {
          basicAlert(err.error, 'warning', 'Aceptar');
        }
        if (err.status == 500) {
          basicAlert('Error conexión del servidor', 'warning', 'Aceptar');
        }
        if (err.status != 400 && err.status != 401 && err.status != 404 && err.status != 500) {
          basicAlert('Ha ocurrido un error inesperado, verifique su conexión a internet', 'warning', 'Aceptar');
        }
        this.spinnerService.activarLoading(false);
      })
  }

  private getListDashboard(id_SHA1: string, id_cliente: number) {

    let orden: string = 'desc';

    this.apiRestServiceService.dashboardObtenerDashboard(id_SHA1, id_cliente, orden).subscribe((response: any) => {
      console.log(response.body)

      localStorage.setItem("dashboardSession", JSON.stringify(response.body.secciones));
      localStorage.setItem("id_tipo_dashboard_Session", JSON.stringify(response.body.id_dashboard_tipo));

      // window.location.reload();

      setTimeout(() => {
        this.TipoDashboard.rutaTipoDashboard(response.body.id_dashboard_tipo);
        document.body.style.backgroundImage = `url()`;
      }, 1000);

      if (response.body.color_theme != null) {
        // this.changeColorEmpresa(response.body.color_theme);
        let color: string = '#ff4c3b'
        if (response.body.color_theme === 'color1') {
          color = '#ff4c3b';
        } else if (response.body.color_theme === 'color2') {
          color = '#3fdda7';
        } else if (response.body.color_theme === 'color3') {
          color = '#f0b54d';
        } else if (response.body.color_theme === 'color4') {
          color = '#e4604a';
        } else if (response.body.color_theme === 'color5') {
          color = '#d4b196';
        } else if (response.body.color_theme === 'color6') {
          color = '#866e6c';
        } else if (response.body.color_theme === 'color7') {
          color = '#cc2121';
        } else if (response.body.color_theme === 'color8') {
          color = '#dc457e';
        } else if (response.body.color_theme === 'color9') {
          color = '#6d7e87';
        } else if (response.body.color_theme === 'color10') {
          color = '#fa869b';
        } else if (response.body.color_theme === 'color11') {
          color = '#81ba00';
        } else if (response.body.color_theme === 'color12') {
          color = '#fe816d';
        }

        changeColorEmpresa(color);
        localStorage.setItem("colorDashboard", color);
      } else {
        changeColorEmpresa('#ff4c3b');
        localStorage.setItem("colorDashboard", '#ff4c3b');
      }
    },
      err => {
        console.log(err);
        this.spinnerService.activarLoading(false);
      });
  }

  private getByIdMoneda(id: number) {
    this.apiRestServiceService.companygetByIdMoneda(id).subscribe((response: any) => {
      console.log(response);
      localStorage.setItem("moneda_compania", JSON.stringify(response.body));
    }, err => {
      console.log(err);
    });
  }

  private getByIdCliente(id: number) {

    let razon: string = '';
    let limit: number = 200;
    let offset: number = 0;

    this.apiRestServiceService.clienteBuscar(id, razon, limit, offset).subscribe((response: any) => {
      console.log(response);

      localStorage.setItem("clienteInfo", JSON.stringify(response.body[0]));
      this.getListCondicionesPagoERP(response.body[0].codtsi);
    }, err => {
      console.log(err);
    });
  }

  getListCondicionesPagoERP(codclitsi: string) {
    this.apiRestServiceService.cpagoListERP(codclitsi).subscribe((response: any) => {
      console.log(response);
      localStorage.setItem("condicionpago", JSON.stringify(response.body));
    }, err => {
      console.log(err);
    });
  }

  getByToDay() {
    this.apiRestServiceService.companygetByTodayTipoCambio().subscribe((response: any) => {
      console.log(response)
      if (response.status == 1) {
        if (response.object != null) {
          localStorage.setItem("tipocambio", response.object.fventa);
        }
      }
    })
  }

  getByIdCompaniaPayPal() {
    this.apiRestServiceService.companygetListPayPal().subscribe((response: any) => {
      console.log(response);
      localStorage.setItem("id_client_paypal", response.body[0].token);
    },
      err => {
        console.log(err);
      });
  }

  private getListBancos() {
    this.apiRestServiceService.companygetListBancos().subscribe((response: any) => {
      console.log(response)
      localStorage.setItem("bancos", JSON.stringify(response.body));
    },
      err => {
        console.log(err);
      });
  }

  onClickRetornar() {
    document.body.style.backgroundImage = `url()`;
    this.TipoDashboard.rutaTipoDashboard(this.id_dashboard_tipo);
  }
}