import { Component, OnInit, Input } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { ProductService } from "../../../services/product.service";
import { basicAlert } from 'src/app/util/utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit {

  @Input() product: any = {};
  @Input() currency: any = this.productService.Currency; // Default Currency 
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  @Input() viewcol3: boolean = false;

  viewDescuento: boolean = false;

  public ImageSrc: string
  public sessionInfo: any = {};
  companiaInfo: any = {};


  constructor(private productService: ProductService,
    private spinnerService: SpinnerService,
    private modalService: NgbModal) {
    this.sessionInfo = JSON.parse(localStorage.getItem("sessionInfo"));
    this.companiaInfo = JSON.parse(localStorage.getItem('companiaInfo'));



  }

  ngOnInit(): void {
    if (this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
  }

  addToCart(product: any) {
    console.log(product)
    if (this.sessionInfo != null) {
      if (product.precio_venta != 0 || product.precio_venta_cliente != 0) {
        this.spinnerService.activarLoading(true)
        this.productService.addToCart(product, this.companiaInfo.oracleUrlErp, this.companiaInfo.oracleIdCia);
      } else {
        basicAlert('El artículo no tiene precio', 'warning', 'Aceptar');
      }
    }
    else {
      basicAlert('Para adicionar articulos al carrito de compras debe iniciar sesión', 'warning', 'Aceptar');
    }
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }

  viewModalArticulo(product: any) {
    this.spinnerService.activarLoading(true)
    const referenciaModal = this.modalService.open(QuickViewComponent, { scrollable: true, windowClass: 'modal-quick-view', backdrop: 'static' });

    referenciaModal.componentInstance.id_articulo = product.id_articulo

    referenciaModal.result.then((data) => {
      console.log(data);

    }, (reason) => {
      console.log(reason);

    });
  }
}