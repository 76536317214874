<div class="main-navbar">
  <div id="mainnav">
    <div class="toggle-nav" (click)="mainMenuToggle()">
      <i class="fa fa-bars sidebar-bar"></i>
    </div>
    <ul class="nav-menu" [class.opennav]="navServices?.mainMenuToggle">

      <li class="back-btn">
        <div class="mobile-back text-right" (click)="mainMenuToggle()">
          <span>Retornar</span>
          <i class="fa fa-angle-right pl-2" aria-hidden="true"></i>
        </div>
      </li>

      <li class="mega" style="margin-right: -1rem;">
        <a class="cursor" (click)="onClickRetornar()">INICIO</a>
      </li>


      <li [class.mega-menu]="true" *ngFor="let lst of codigosPadre">
        <!-- Sub -->
        <a href="javascript:void(0)" class="nav-link" *ngIf="codigosPadre != null"
          (click)="toggletNavActive(lst)">{{lst.title}}
          <span class="sub-arrow"></span>
        </a>

        <ul class="nav-submenu" [class.opensubmenu]="lst.active">
          <li *ngFor="let lst2 of lst.codigos">
            <!-- Link -->
            <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="toggletNavActive(lst2)">
              {{ lst2.descri }}
              <span class="sub-arrow" *ngIf="lst2.codigos"></span>
            </a>

            <!-- 3rd Level Menu -->
            <ul *ngIf="lst2.codigos" [class.opensubchild]="lst2.active" class="nav-sub-childmenu">
              <li *ngFor="let lst3 of lst2.codigos">
                <!-- Link -->
                <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                  (click)="onClickFiltroCodigos(lst3)">
                  {{ lst3.descri }}
                </a>
              </li>
            </ul>
          </li>
        </ul>

        <div class="mega-menu-container">
          <div class="container">
            <div class="row">
              <div class="col mega-box" *ngFor="let lst2 of lst.codigos">
                <div class="link-section">
                  <div class="menu-title">
                    <h5 class="cursor" (click)="onClickFiltroCodigosPadre(lst2)">
                      {{ lst2.descri }}
                    </h5>
                  </div>
                  <div class="menu-content opensubmegamenu">
                    <ul>
                      <li *ngFor="let lst3 of lst2.codigos | sort : 'descri' : 'asc'">
                        <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="cursor"
                          (click)="onClickFiltroCodigos(lst3)">
                          {{ lst3.descri }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li class="mega" style="margin-right: -1rem;" *ngIf="viewCatalogos">
        <a class="cursor" (click)="cargandoCatalogo()">Catálogos</a>
      </li>

      <li class="mega" style="margin-right: -1rem;">
        <a class="cursor" (click)="cargandoContacto()">CONTACTO</a>
      </li>

      <li class="mega" style="margin-right: -1rem;" *ngIf="viewBlog">
        <a class="cursor" (click)="cargandoBlog()">Blog</a>
      </li>
    </ul>
  </div>
</div>