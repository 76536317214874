import { Component, OnInit, Input, HostListener } from '@angular/core';
import { SpinnerService } from '../../../services/spinner.service';
import { Router } from '@angular/router';
import { RutasTipoDashboardService } from '../../../services/rutas-tipo-dashboard.service';
import { GlobalConstants } from 'src/app/models/global-constants';

@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {

  @Input() class: string;
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  @Input() themeLogo: string = ''; // Default Logo

  stick: boolean = false;
  sessionInfo: any = {};
  nombres: string = '';
  empresa: string = '';
  id_dashboard_tipo: number = null;
  companiaInfo: any = {};
  clienteInfo: any = {};

  constructor(
    private spinnerService: SpinnerService,
    private router: Router,
    private TipoDashboard: RutasTipoDashboardService) {
    this.sessionInfo = JSON.parse(localStorage.getItem("sessionInfo"));
    this.id_dashboard_tipo = +localStorage.getItem("id_tipo_dashboard");
    this.companiaInfo = JSON.parse(localStorage.getItem("companiaInfo"));
    this.clienteInfo = JSON.parse(localStorage.getItem("clienteInfo"));

    if (this.companiaInfo != null) {
      this.themeLogo = `${GlobalConstants.urlService}/companias/view/image/${this.companiaInfo.id_imagen_logo}`;
    }
  }

  ngOnInit(): void {
    let apepat: string = '';
    let apemat: string = '';

    if (this.clienteInfo != null) {
      apepat = this.clienteInfo.apellidopat != null ? this.clienteInfo.apellidopat + ' ' : ''
      apemat = this.clienteInfo.apellidomat != null ? this.clienteInfo.apellidomat : ''
      this.nombres = this.clienteInfo.nombres + ' ' + apepat + apemat;
      this.empresa = this.clienteInfo.razon != null ? '(' + this.clienteInfo.razon + ')' : '';
    }
  }

  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number >= 300 && window.innerWidth > 400) {
      this.stick = true;
    } else {
      this.stick = false;
    }
  }

  onCLicMiPerfil() {
    if (!window.location.href.includes('ecommerce/pages/perfil')) {
      this.spinnerService.activarLoading(true);
      this.router.navigateByUrl(`/ecommerce/pages/perfil`);
    }
  }

  onCLicMisPedidos() {
    if (!window.location.href.includes('ecommerce/pages/pedidos')) {
      this.spinnerService.activarLoading(true);
      this.router.navigateByUrl(`/ecommerce/pages/pedidos`);
    }
  }

  onCLicFavoritos() {
    if (!window.location.href.includes('ecommerce/shop/favoritos')) {
      this.spinnerService.activarLoading(true);
      this.router.navigateByUrl(`/ecommerce/shop/favoritos`);
    }
  }

  onClickRetornar() {
    this.TipoDashboard.rutaTipoDashboard(this.id_dashboard_tipo);
  }

  public cerrar() {
    if (this.sessionInfo != null) {
      localStorage.removeItem('nombre');
      localStorage.removeItem('correo');
      localStorage.removeItem('clave');
      localStorage.removeItem('token');
      localStorage.setItem('sessionInfo', null);
      localStorage.removeItem("cartItems");
      localStorage.removeItem("dashboardSession");
      localStorage.removeItem("id_tipo_dashboard_Session");
      localStorage.removeItem("fecInicioSesion");
      localStorage.removeItem("clienteInfo");
      localStorage.removeItem("condicionpago");
      localStorage.removeItem("tipocambio");
      localStorage.removeItem("moneda_compania");
      localStorage.removeItem("bancos");

      this.TipoDashboard.rutaTipoDashboard(this.id_dashboard_tipo);
      setTimeout(() => {
        window.location.reload();
      }, 250)
    }
  }
}
