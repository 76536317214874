import { Injectable } from '@angular/core';
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class RutasTipoDashboardService {

  constructor(private router: Router) { }

  rutaTipoDashboard(id_dashboard_tipo: number) {
    if (id_dashboard_tipo == 1) {
      this.router.navigateByUrl(`/ecommerce/dashboard/home1`);
    } else if (id_dashboard_tipo == 2) {
      this.router.navigateByUrl(`/ecommerce/dashboard/home2`);
    } else if (id_dashboard_tipo == 3) {
      this.router.navigateByUrl(`/ecommerce/dashboard/home3`);
    } else if (id_dashboard_tipo == 4) {
      this.router.navigateByUrl(`/ecommerce/dashboard/home4`);
    } else if (id_dashboard_tipo == 5) {
      this.router.navigateByUrl(`/ecommerce/dashboard/home5`);
    } else if (id_dashboard_tipo == 6) {
      this.router.navigateByUrl(`/ecommerce/dashboard/home/furniture`);
    } else if (id_dashboard_tipo == 7) {
      this.router.navigateByUrl(`/ecommerce/dashboard/home7`);
    } else if (id_dashboard_tipo == 8) {
      this.router.navigateByUrl(`/ecommerce/dashboard/home8`);
    } else if (id_dashboard_tipo == 9) {
      this.router.navigateByUrl(`/ecommerce/dashboard/home9`);
    } else if (id_dashboard_tipo == 10) {
      this.router.navigateByUrl(`/ecommerce/dashboard/home10`);
    } else if (id_dashboard_tipo == 11) {
      this.router.navigateByUrl(`/ecommerce/dashboard/home11`);
    } else if (id_dashboard_tipo == 12) {
      this.router.navigateByUrl(`/ecommerce/dashboard/home12`);
    } else if (id_dashboard_tipo == 13) {
      this.router.navigateByUrl(`/ecommerce/dashboard/home/tools`);
    } else if (id_dashboard_tipo == 14) {
      this.router.navigateByUrl(`/ecommerce/dashboard/home14`);
    }
  }
}
