
<div class="theme-card">
  <h5 class="title-border">{{ title }}</h5>
  <owl-carousel-o [options]="NewProductSliderConfig" class="offer-slider">
    <ng-container>
      <ng-template carouselSlide>
        <div>
          <div class="media" *ngFor="let lst of products | slice:0:5">
            <div *ngIf="lst.precio_venta > 0">
              <div *ngIf="lst.precio_cliente === null">
                <div class="lable-block" *ngIf="lst.desc_total != 0">
                  <span class="circulo" *ngIf="!lst.viewdesc">
                    <label class="text-transform-none">DSCTO.</label>
                  </span>
        
                  <span class="circulo2" *ngIf="lst.viewdesc">
                    <label class="text-transform-none">{{lst.desc_total | number: '0.0-0'}}%<br>
                      <div style="font-size: 0.6rem !important">dscto.</div>
                    </label>
                  </span>
                </div>
              </div>
            </div>
            <div *ngIf="lst.precio_venta_cliente > 0">
              <div *ngIf="lst.precio_cliente != null">
                <div class="lable-block" *ngIf="lst.desc_total_cliente != 0">
                  <span class="circulo" *ngIf="!lst.viewdesc">
                    <label class="text-transform-none">DSCTO.</label>
                  </span>
        
                  <span class="circulo2" *ngIf="lst.viewdesc">
                    <label class="text-transform-none">{{lst.desc_total_cliente | number: '0.0-0'}}%<br>
                      <div style="font-size: 0.6rem !important">dscto.</div>
                    </label>
                  </span>
                </div>
              </div>
            </div>
            <a class="cursor" (click)="redirectoDetalle(lst.id)">
              <img class="img-fluid w-auto" [defaultImage]="'assets/images/lst/placeholder.jpg'"
                [lazyLoad]="lst.img" alt="">
            </a>
            <div class="media-body align-self-center">
              <a class="cursor" (click)="redirectoDetalle(lst.id)">
                <h6>{{ lst.descri }}</h6>
              </a>
              <div *ngIf="lst.precio_venta > 0">
                <h4 *ngIf="lst.precio_cliente == null">
                  {{lst.moneda != null ? lst.moneda : 'S/.'}}{{lst.precio_venta | number : '0.2-2'}}
                  <del *ngIf="lst.desc01 != null || lst.desc02 != null || lst.desc03 != null || lst.desc04 != null">
                    <span class="money" *ngIf="lst.desc01 > 0 || lst.desc02 > 0 || lst.desc03 > 0 || lst.desc04 > 0">
                      {{lst.moneda != null ? lst.moneda : 'S/.'}}{{lst.precio | number : '0.2-2'}}
                    </span>
                  </del>
                </h4>
              </div>
              <div *ngIf="lst.precio_venta_cliente > 0">
                <h4 *ngIf="lst.precio_cliente != null">
                  {{lst.moneda_cliente != null ? lst.moneda_cliente : 'S/.'}}{{lst.precio_venta_cliente | number : '0.2-2'}}
                  <del
                    *ngIf="lst.desc01_cliente != null || lst.desc02_cliente != null || lst.desc03_cliente != null || lst.desc04_cliente != null">
                    <span class="money"
                      *ngIf="lst.desc01_cliente > 0 || lst.desc02_cliente > 0 || lst.desc03_cliente > 0 || lst.desc04_cliente > 0">
                      {{lst.moneda != null ? lst.moneda : 'S/.'}}{{lst.precio_cliente | number : '0.2-2'}}
                    </span>
                  </del>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div>
          <div class="media" *ngFor="let lst of products | slice:5:10 ">
            <a class="cursor" (click)="redirectoDetalle(lst.id)">
              <img class="img-fluid w-auto" [defaultImage]="'assets/images/product/placeholder.jpg'"
                [lazyLoad]="lst.img" alt="">
            </a>
            <div class="media-body align-self-center">
              <a class="cursor" (click)="redirectoDetalle(lst.id)">
                <h6>{{ lst.descri }}</h6>
              </a>
              <div *ngIf="lst.precio_venta > 0">
                <h4 *ngIf="lst.precio_cliente == null">
                  {{lst.moneda != null ? lst.moneda : 'S/.'}}{{lst.precio_venta | number : '0.2-2'}}
                  <del *ngIf="lst.desc01 != null || lst.desc02 != null || lst.desc03 != null || lst.desc04 != null">
                    <span class="money" *ngIf="lst.desc01 > 0 || lst.desc02 > 0 || lst.desc03 > 0 || lst.desc04 > 0">
                      {{lst.moneda != null ? lst.moneda : 'S/.'}}{{lst.precio | number : '0.2-2'}}
                    </span>
                  </del>
                </h4>
              </div>
              <div *ngIf="lst.precio_venta_cliente > 0">
                <h4 *ngIf="lst.precio_cliente != null">
                  {{lst.moneda_cliente != null ? lst.moneda_cliente : 'S/.'}}{{lst.precio_venta_cliente | number : '0.2-2'}}
                  <del
                    *ngIf="lst.desc01_cliente != null || lst.desc02_cliente != null || lst.desc03_cliente != null || lst.desc04_cliente != null">
                    <span class="money"
                      *ngIf="lst.desc01_cliente > 0 || lst.desc02_cliente > 0 || lst.desc03_cliente > 0 || lst.desc04_cliente > 0">
                      {{lst.moneda != null ? lst.moneda : 'S/.'}}{{lst.precio_cliente | number : '0.2-2'}}
                    </span>
                  </del>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
  