export class ArticuloStockModel {
    public id_cia: number;
    public id: number;
    public tipinv: number;
    public codart: string;
    public descri: string;
    public codund: string;
    public code: string;
    public detalle: string;
    public subdetalle: string;
    public especificaciones: string;
    public ind_nuevo: boolean;
    public activo: boolean;
    public ind_comentario: string;
    public url_video: string;
    public id_imagen: number;
    public codadd01: string;
    public codadd02: string;
    public stock: number;
}