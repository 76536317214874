import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public clases: any[] = [];
  public collapse: boolean = true;

  constructor(public productService: ProductService,
    private router: Router,
    private spinnerService: SpinnerService) {
    this.clases = JSON.parse(localStorage.getItem("menuFooter"));
  }

  ngOnInit(): void {  }

  onClickFiltroSeccines(data: any) {
    console.log(data)
    this.spinnerService.activarLoading(true);
    if (window.location.href.includes('buscar/articulos/')) {
      this.router.navigateByUrl('/DummyComponent', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/ecommerce/shop/buscar/articulos'], { queryParams: { clase: data.clase, codigo: data.codigo, menu: 'Categoria' } }));
    } else {
      this.router.navigate(['/ecommerce/shop/buscar/articulos'], { queryParams: { clase: data.clase, codigo: data.codigo, menu: 'Categoria' } });
    }
  }
}