<div class="icon-nav">
  <ul>
    <li class="onhover-div mobile-search search-widgets" id="search-widgets">
      <div>
        <img src="assets/images/icon/search.png" (click)="openSearch()" class="img-fluid" alt="">
        <i class="ti-search" (click)="openSearch()"></i>
      </div>
    </li>
    <li class="onhover-div mobile-cart">
      <div>
        <a>
          <img src="assets/images/icon/cart.png" class="img-fluid" alt="">
          <i class="ti-shopping-cart"></i>
        </a>
      </div>
      <span class="cart_qty_cls">{{ products.length }}</span>
      <ul class="show-div shopping-cart" *ngIf='!products.length'>
        <h5>Su carrito está vacío.</h5>
      </ul>
      <ul class="show-div shopping-cart" *ngIf='products.length' style="min-width: 300px !important;">
        <li *ngFor="let product of products">
          <div class="media">
            <a>
              <img class="mr-3" [src]="product?.url_imagen" [alt]="product?.url_imagen">
            </a>
            <div class="media-body">
              <a>
                <h4>{{ product?.descri }}</h4>
              </a>
              <h4 *ngIf="product.precio_cliente === null">
                <span>
                  {{ product?.quantity }} x {{ product?.precio_venta | number: '.2-2' }}
                </span>
              </h4>
              <h4 *ngIf="product.precio_cliente != null">
                <span>
                  {{ product?.quantity }} x {{ product?.precio_venta_cliente | number: '.2-2' }}
                </span>
              </h4>
            </div>
          </div>
          <div class="close-circle" (click)="removeItem(product)">
            <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
          </div>
        </li>
        <li>
          <div class="total">
            <h5>subtotal : <span>{{ moneda.simbolo }} {{ getTotal }}</span>
            </h5>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a [routerLink]="['/ecommerce/shop/cart']" class="view-cart text-transform">Ver carrito</a>
            <a [routerLink]="['/ecommerce/shop/checkout']" class="checkout text-transform">Realizar pedido</a>
          </div>
        </li>
        <!-- <li *ngIf='products.length < 3'>
          <div class="total">
            <h5>subtotal : <span>{{ moneda.simbolo }} {{ getTotal }}</span>
            </h5>
          </div>
        </li>
        <li *ngIf='products.length < 3'>
          <div class="buttons">
            <a [routerLink]="['/ecommerce/shop/cart']" class="view-cart text-transform">Ver carrito</a>
            <a [routerLink]="['/ecommerce/shop/checkout']" class="checkout text-transform">Realizar pedido</a>
          </div>
        </li> -->
        <!-- <ul class="show-div shopping-cart" *ngIf='products.length >= 3'>
          <li>
            <div class="total">
              <h5>subtotal : <span>{{moneda.simbolo}} {{ getTotal }}</span></h5>
            </div>
          </li>
          <li>
            <div class="buttons">
              <a [routerLink]="['/ecommerce/shop/cart']" class="view-cart text-transform">Ver carrito</a>
              <a [routerLink]="['/ecommerce/shop/checkout']" class="checkout text-transform">Realizar pedido</a>
            </div>
          </li>
        </ul> -->
      </ul>
    </li>

    <li class="onhover-div mobile-tsi" *ngIf="viewERP">
      <div>
        <img src="assets/images/icon/cloud3.jpg" class="img-fluid" alt="">
        <i class="ti-cloud"></i>
      </div>
      <ul class="show-div shopping-tsi onhover-dropdown2">

        <!-- <li>
          <a href="https://web.grupotsiperu.com.pe:8444/#" target="_blank" style="color: black !important;">ERP TSI</a>
        </li> -->



        <!-- <li>
          <a href="https://web.grupotsiperu.com.pe:8445/#/login" target="_blank" style="color: black !important;">
            <b>ERP TSI - Oracle</b></a>
        </li>

        <li>
          <a href="https://web.grupotsiperu.com.pe:8454/login" target="_blank" style="color: black !important;">
            <b>RRHH TSI - Oracle</b></a>
        </li> -->

        <li>
          <a href="https://web.grupotsiperu.com.pe:8843/#/login" target="_blank" style="color: black !important;">
            <b>TSI Suite - Oracle</b></a>
        </li>

      </ul>
    </li>

    <li class="onhover-div mobile-factura">
      <div>
        <img src="assets/images/icon/file_cpe.png" class="img-fluid" alt="">
        <i class="ti-zip"></i>
      </div>
      <ul class="show-div cpe-tsi onhover-dropdown2">
        <li>
          <a href="https://facturacion.grupotsiperu.com.pe:8445/grupotsi-web/consultas?empresa={{companiaInfo.razon}}"
            target="_blank" style="color: black !important;">CPE - TSI</a>
        </li>
        <li>
          <a href="https://web.grupotsiperu.com.pe:8449/#/consulta-facturador?empresa={{companiaInfo.razon}}"
            target="_blank" style="color: black !important;">CPE - TSI Oracle</a>
        </li>
      </ul>
    </li>
  </ul>
</div>

<div id="search-overlay" class="search-overlay" *ngIf="show" [ngStyle]="{ 'display': 'block' }">
  <div>
    <span class="closebtn" title="Close Overlay" (click)="closeSearch()">×</span>
    <div class="overlay-content">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <form>
              <div class="form-group">
                <input type="text" class="form-control" #busqueda name="filtro" [(ngModel)]="filtro"
                  placeholder="Búsqueda de artículos" (keydown.enter)="filtroArticulo()">
              </div>
              <button type="button" class="btn btn-primary" (click)="filtroArticulo()"><i
                  class="fa fa-search"></i></button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>