import { Component, OnInit, Input } from '@angular/core';
import { RutasTipoDashboardService } from 'src/app/services/rutas-tipo-dashboard.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() title : string = '';
  @Input() ruta: string = '';
  @Input() breadcrumb : string = '';
  @Input() breadcrumb2 : string = '';

  public id: number = null;
  public id_dashboard_tipo: number = null;
  
  constructor(private tipodashboard: RutasTipoDashboardService,
    private _route: ActivatedRoute) {
    this.id = +this._route.snapshot.paramMap.get('id');
    this.id_dashboard_tipo = +localStorage.getItem("id_tipo_dashboard");
  }

  ngOnInit() : void {  }

  onClickRetornar() {
    this.tipodashboard.rutaTipoDashboard(this.id_dashboard_tipo);
  }
}