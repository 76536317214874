import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeVideo'
})
export class SafeVideoPipe implements PipeTransform {

  constructor( private _sanitizer: DomSanitizer ){}

  transform(url: any): any {
      var video, results;

      if (url === null) {
          return '';
      }
      results = url.match('[\\?&]v=([^&#]*)');
      video   = (results === null) ? url : results[1];
      return this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video);  
  }
}