<div *ngIf="!loader">
  <div class="img-wrapper">
    <!-- <div class="lable-block">
      <span class="lable3" *ngIf="product.new">new</span>
      <span class="lable4" *ngIf="product.sale">on sale</span>
    </div> -->
    <div *ngIf="product.precio_venta > 0">
      <div *ngIf="product.precio_cliente === null">
        <div class="lable-block" *ngIf="product.desc_total != 0">
          <span class="circulo" *ngIf="!product.viewdesc">
            <label class="text-transform-none">DSCTO.</label>
          </span>

          <span class="circulo2" *ngIf="product.viewdesc">
            <label class="text-transform-none">{{product.desc_total | number: '0.0-0'}}%<br>
              <div style="font-size: 0.6rem !important; margin-top: -.4rem;">dscto.</div>
            </label>
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="product.precio_venta_cliente > 0">
      <div *ngIf="product.precio_cliente != null">
        <div class="lable-block" *ngIf="product.desc_total_cliente != 0">
          <span class="circulo" *ngIf="!product.viewdesc">
            <label class="text-transform-none">DSCTO.</label>
          </span>

          <span class="circulo2" *ngIf="product.viewdesc">
            <label class="text-transform-none">{{product.desc_total_cliente | number: '0.0-0'}}%<br>
              <div style="font-size: 0.6rem !important; margin-top: -.4rem;">dscto.</div>
            </label>
          </span>
        </div>
      </div>
    </div>
    <div class="front" *ngIf="companiaInfo.labelagotado">
      <a *ngIf="product.stock != 0" routerLink="/ecommerce/shop/left/detalle/articulo/{{product.id_articulo}}"><img
          [src]="product.url_imagen" class="img-fluid" alt="" style="max-width: 77%;"></a>
      <a *ngIf="product.stock == 0" [routerLink]="['/ecommerce/shop/left/detalle/articulo', product.id_articulo]">
        <img [src]="product.url_imagen" class="img-fluid" alt="" style="opacity: 0.6;" style="max-width: 77%;">
        <img src="assets/images/agotado.png" class="img-fluid img2" alt="">
      </a>
    </div>

    <div class="front" *ngIf="!companiaInfo.labelagotado">
      <a routerLink="/ecommerce/shop/left/detalle/articulo/{{product.id_articulo}}">
          <img [src]="product.url_imagen" class="img-fluid" alt=""></a>
  </div>
    <div class="cart-info cart-wrap">
      <a href="javascript:void(0)" title="Añadir a carrito" (click)="addToCart(product)" *ngIf="!cartModal">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Añadir a favoritos" (click)="addToWishlist(product)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Vista rapida" (click)="viewModalArticulo(product)">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <div class="product-detail">
    <div>
      <div style="margin-top: 1.6rem !important;"></div>
      <a [routerLink]="['/ecommerce/shop/left/detalle/articulo', product.id_articulo]">
        <h6>{{product.descri}}</h6>
      </a>
      <a>
        <h6 class="mt-1 mb-1" style="font-weight: bold;">{{product.code}}</h6>
      </a>
      <!-- PRECIO -->
      <div class="form-row" *ngIf="product.precio_venta > 0">

        <div *ngIf="!viewcol3"
          [ngClass]="{'col-md-5': product.colviewdesc === false, 'col-md-12': product.colviewdesc === true}">
          <h4 *ngIf="product.precio_cliente == null">
            {{product.moneda != null ? product.moneda : 'S/.'}}
            {{product.precio_venta | number : '.2-2'}}
          </h4>
        </div>

        <div *ngIf="viewcol3" class="col-md-12">
          <h3 *ngIf="product.precio_cliente == null">
            {{product.moneda != null ? product.moneda : 'S/.'}}
            {{product.precio_venta | number : '.2-2'}}
          </h3>
        </div>

        <div *ngIf="!viewcol3" class="col-md-7">
          <div *ngIf="!product.colviewdesc">
            <div *ngIf="product.desc03 > 0 && product.desc04 > 0">
              <del>
                <span class="descuento">
                  {{product.moneda != null ? product.moneda : 'S/.'}}
                  {{product.precio_venta_desc3 | number : '.2-2'}}
                </span>
              </del>
              <span class="descuento ml-2">
                + {{ product.desc04 | number : '.0-0' }}% dscto.
              </span>
            </div>

            <div *ngIf="product.desc02 > 0 && product.desc03 > 0">
              <del>
                <span class="descuento">
                  {{product.moneda != null ? product.moneda : 'S/.'}}
                  {{product.precio_venta_desc2 | number : '.2-2'}}
                </span>
              </del>
              <span class="descuento ml-2">
                +{{ product.desc03 | number : '.0-0' }}% dscto.
              </span>
            </div>

            <div *ngIf="product.desc01 > 0 && product.desc02 > 0">
              <del>
                <span class="descuento">
                  {{product.moneda != null ? product.moneda : 'S/.'}}
                  {{product.precio_venta_desc1 | number : '.2-2'}}
                </span>
              </del>
              <span class="descuento ml-2">
                + {{ product.desc02 | number : '.0-0' }}% dscto.
              </span>
            </div>

            <div *ngIf="product.precio > 0 && product.desc01 > 0">
              <del>
                <span class="descuento">
                  {{product.moneda != null ? product.moneda : 'S/.'}}
                  {{product.precio | number : '.2-2'}}
                </span>
              </del>
              <span class="descuento ml-2">
                {{ product.desc01 | number : '.0-0' }}% dscto.
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- PRECIO CLIENTE -->
      <div class="form-row" *ngIf="product.precio_venta_cliente > 0">
        <div *ngIf="!viewcol3"
          [ngClass]="{'col-md-5': product.colviewdesc === false, 'col-md-12': product.colviewdesc === true}">
          <h4 *ngIf="product.precio_cliente != null">
            {{product.moneda_cliente != null ? product.moneda_cliente : 'S/.'}}
            {{product.precio_venta_cliente | number : '.2-2'}}
          </h4>
        </div>

        <div *ngIf="viewcol3" class="col-md-12">
          <h3 *ngIf="product.precio_cliente != null">
            {{product.moneda_cliente != null ? product.moneda_cliente : 'S/.'}}
            {{product.precio_venta_cliente | number : '.2-2'}}
          </h3>
        </div>

        <div *ngIf="!viewcol3" class="col-md-7">
          <div class="col-md-7">
            <div *ngIf="product.desc03_cliente > 0 && product.desc04_cliente > 0">
              <del>
                <span class="descuento">
                  {{product.moneda_cliente != null ? product.moneda_cliente : 'S/.'}}
                  {{product.precio_venta_cliente_desc3 | number : '.2-2'}}
                </span>
              </del>
              <span class="descuento ml-2">
                + {{ product.desc04_cliente | number : '.0-0' }}% dscto.
              </span>
            </div>

            <div *ngIf="product.desc02_cliente > 0 && product.desc03_cliente > 0">
              <del>
                <span class="descuento">
                  {{product.moneda_cliente != null ? product.moneda_cliente : 'S/.'}}
                  {{product.precio_venta_cliente_desc2 | number : '.2-2'}}
                </span>
              </del>
              <span class="descuento ml-2">
                +{{ product.desc03_cliente | number : '.0-0' }}% dscto.
              </span>
            </div>

            <div *ngIf="product.desc01_cliente > 0 && product.desc02_cliente > 0">
              <del>
                <span class="descuento">
                  {{product.moneda_cliente != null ? product.moneda_cliente : 'S/.'}}
                  {{product.precio_venta_cliente_desc1 | number : '.2-2'}}
                </span>
              </del>
              <span class="descuento ml-2">
                + {{ product.desc02_cliente | number : '.0-0' }}% dscto.
              </span>
            </div>

            <div *ngIf="product.precio_cliente > 0 && product.desc01_cliente > 0">
              <del>
                <span class="descuento">
                  {{product.moneda_cliente != null ? product.moneda_cliente : 'S/.'}}
                  {{product.precio_cliente | number : '.2-2'}}
                </span>
              </del>
              <span class="descuento ml-2">
                {{ product.desc01_cliente | number : '.0-0' }}% dscto.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box> -->