import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router'
import { ClienteModel } from '../models/cliente.model';
import { LoginService } from './login.service';
import * as moment from 'moment';
import { RutasTipoDashboardService } from './rutas-tipo-dashboard.service';
import { basicAlert } from '../util/utils';
import { SpinnerService } from './spinner.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  public clienteModel = new ClienteModel();
  public id_dashboard_tipo: number = null;

  constructor(private router: Router,
    private TipoDashboard: RutasTipoDashboardService,
    private spinnerService: SpinnerService,
    private loginService: LoginService) {
    moment.locale('es-PE');
    this.id_dashboard_tipo = +localStorage.getItem("id_tipo_dashboard");
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /*La logica 
21:18 si pasan menos de 10 minutos  hasta las 21:28:
seguir con el token de sesion.
en caso contrario:
VOLVER A CONSULTAR  :
Y SETEAR LA HORA NUEVAMENTE 
*/
    let request = req;






    let fechaSesion = moment(localStorage.getItem('fecInicioSesion'), 'HH:mm:ss')
    let fechaActual = moment(new Date(), 'HH:mm:ss');
    let tiempoSesion = fechaActual.diff(fechaSesion, 'minutes', true)
    let _token: string

    if (!tiempoSesion || tiempoSesion < 10) {
      _token = localStorage.getItem('token');

    } else if (tiempoSesion > 10) {
      let fecInicioSesion = moment(new Date()).format("HH:mm:ss");
      localStorage.setItem('fecInicioSesion', fecInicioSesion);
      let id_SHA1 = localStorage.getItem("id_SHA")

      this.clienteModel.email = localStorage.getItem('correo');
      this.clienteModel.clave = localStorage.getItem('clave');

      this.loginService.login(id_SHA1, this.clienteModel).subscribe((response: any) => {
        localStorage.setItem('token', response.token);
        _token = response.token;
      })
    }



    return this.reLogin(_token, request, req, next);
  }

  private reLogin(_token, request: any, req, next: any) {
    const re = 'vnforapps.com';

    if (req.url.search(re) === -1) {
      if (_token != undefined && _token != null && _token.length > 0) {

        request = req.clone({
          setHeaders: {
            Authorization: `${_token}`
          }
        });


      }
    }
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.spinnerService.activarLoading(false);
          basicAlert('Su sesión ha expirado por inactividad en la aplicación', 'error', 'Aceptar');
          localStorage.removeItem('nombre');
          localStorage.removeItem('correo');
          localStorage.removeItem('clave');
          localStorage.removeItem('token');
          localStorage.setItem('sessionInfo', null);
          localStorage.removeItem('fecInicioSesion');
          localStorage.removeItem("cartItems");
          localStorage.removeItem("dashboardSession");
          localStorage.removeItem("id_tipo_dashboard_Session");
          localStorage.removeItem("clienteInfo");
          localStorage.removeItem("condicionpago");
          localStorage.removeItem("tipocambio");
          localStorage.removeItem("moneda_compania");
          this.router.navigateByUrl('/login');
        }
        return throwError(err);
      })
    );
  }
}