import { Component, OnInit } from '@angular/core';
import { NavService } from '../../services/nav.service';
import { Router } from '@angular/router';
import { ArticuloService } from '../../..//services/articulo.service';
import { RutasTipoDashboardService } from '../../..//services/rutas-tipo-dashboard.service';
import { SpinnerService } from '../../../services/spinner.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public codigosPadre: any[] = [];
  public articulos: any[] = [];
  public alias: string = '';
  public idCia_SHA1: string = '';
  public sessionInfo: any = {};
  public viewBlog: boolean = false;
  public viewCatalogos: boolean = false;
  public id_dashboard_tipo: number = null;
  public descriClase: string = '';

  public activeProducto: boolean = false;

  constructor(private router: Router, public navServices: NavService,
    private articuloService: ArticuloService,
    private TipoDashboard: RutasTipoDashboardService,
    private spinnerService: SpinnerService) {

    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });

    let codigos: any[] = [];
    let catalogos: any[] = [];
    let secciones: any[] = [];
    let codigoActiveObj: any = {}
    let codigoActiveObj1: any = {}
    let codigoActive: any[] = []
    let codigoActive1: any[] = []

    this.sessionInfo = JSON.parse(localStorage.getItem("sessionInfo"));
    this.idCia_SHA1 = localStorage.getItem("id_SHA");
    this.id_dashboard_tipo = +localStorage.getItem("id_tipo_dashboard");

    codigos = JSON.parse(localStorage.getItem("menuSuperiorHorizontal"));

    if(codigos != null){
      codigos.forEach(element => {
        codigoActiveObj = {
          codigo: element.codigo,
          codigos: element.codigos,
          descri: element.descri,
          active: false
        }
        codigoActive.push(codigoActiveObj)
      });
  
      codigoActiveObj1 = {
        title: 'Productos',
        active: false,
        codigos: codigoActive
      }
  
      codigoActive1.push(codigoActiveObj1)
    }

    if (codigos == null) {
      this.codigosPadre = null;
    } else if (codigos.length == 0) {
      this.codigosPadre = null;
    } else {
      this.codigosPadre = codigoActive1;
    }

    if (this.sessionInfo == null) {
      secciones = JSON.parse(localStorage.getItem("dashboardDefault"));
      if (secciones != null) {
        secciones = secciones.filter(s => s.tipoDesc == 'Blog');
        if (secciones.length > 0) {
          secciones.forEach(element => {
            if (element.detalle.length > 0) {
              this.viewBlog = true;
            }
          });
        }
      }
    } else if (this.sessionInfo != null) {
      secciones = JSON.parse(localStorage.getItem("dashboardSession"));
      if (secciones != null) {
        secciones = secciones.filter(s => s.tipoDesc == 'Blog');
        if (secciones.length > 0) {
          secciones.forEach(element => {
            if (element.detalle.length > 0) {
              this.viewBlog = true;
            }
          });
        }
      }
    }

    catalogos = JSON.parse(localStorage.getItem("catalogos"));
    if (catalogos != null) {
      if (catalogos.length > 0) {
        this.viewCatalogos = true;
      }
    }
  }

  ngOnInit(): void {
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(data: any) {
    data.active = !data.active;
  }


  onClickFiltroCodigosPadre(data: any) {
    console.log(data)
    localStorage.setItem("busqueda", 'menu superior');
    if (window.location.href.includes('buscar/articulos/')) {
      this.router.navigateByUrl('/DummyComponent', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/ecommerce/shop/buscar/articulos'], { queryParams: { busqueda: '', clase: '', codigo: data.codigo, seccion: '', preciomax: '', preciomin: '', menu: 'Superior' , sortBy: '' } }));
    } else {
      this.router.navigate(['/ecommerce/shop/buscar/articulos'], { queryParams: { busqueda: '', clase: '', codigo: data.codigo, seccion: '', preciomax: '', preciomin: '', menu: 'Superior' , sortBy: '' } });
    }
  }

  onClickFiltroCodigos(data: any) {
    console.log(data)
    localStorage.setItem("busqueda", 'menu superior');
    if (window.location.href.includes('buscar/articulos/')) {
      this.router.navigateByUrl('/DummyComponent', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/ecommerce/shop/buscar/articulos'], { queryParams: { busqueda: '', clase: data.clase, codigo: data.codigo, seccion: '', preciomax: '', preciomin: '', menu: 'Superior', sortBy: '' } }));
    } else {
      this.router.navigate(['/ecommerce/shop/buscar/articulos'], { queryParams: { busqueda: '', clase: data.clase, codigo: data.codigo, seccion: '', preciomax: '', preciomin: '', menu: 'Superior', sortBy: '' } });
    }
  }

  cargandoCatalogo() {
    if (!window.location.href.includes('ecommerce/pages/catalogo')) {
      this.spinnerService.activarLoading(true);
      this.router.navigateByUrl(`/ecommerce/pages/catalogo`);
    }
  }

  cargandoContacto() {
    if (!window.location.href.includes('ecommerce/pages/contacto')) {
      this.spinnerService.activarLoading(true);
      this.router.navigateByUrl(`/ecommerce/pages/contacto`);
    }
  }

  cargandoBlog() {
    if (!window.location.href.includes('ecommerce/pages/blogs')) {
      this.spinnerService.activarLoading(true);
      this.router.navigateByUrl(`/ecommerce/pages/blogs`);
    }
  }

  onClickRetornar() {
    this.TipoDashboard.rutaTipoDashboard(this.id_dashboard_tipo);
  }
}