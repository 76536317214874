import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  constructor(private spinner: NgxSpinnerService) { }

  activarLoading(encendido: boolean) {
    if (encendido) {
      this.spinner.show(undefined, { fullScreen: true, type: "ball-spin-clockwise" });
    }
    else {
      this.spinner.hide();
    }
  }
}
