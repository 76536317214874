import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner.service';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-secciones',
  templateUrl: './secciones.component.html',
  styleUrls: ['./secciones.component.scss']
})
export class SeccionesComponent implements OnInit {

  public secciones: any[] = [];
  public collapse: boolean = true;

  constructor(public productService: ProductService,
    private router: Router,
    private spinnerService: SpinnerService) {
    let tabs: any = {};
    tabs = JSON.parse(localStorage.getItem("infoTabs"))
    if (tabs != null)
      tabs.forEach(element => {
        let obj = {
          id: element.articuloSeccion.id,
          titulo: element.articuloSeccion.titulo
        }

        this.secciones.push(obj)
      });
    console.log(this.secciones)
  }

  ngOnInit(): void { }

  onClickFiltroSeccines(seccion: any) {
    this.spinnerService.activarLoading(true);
    if (window.location.href.includes('buscar/articulos/')) {
      this.router.navigateByUrl('/DummyComponent', { skipLocationChange: true }).then(() =>
        this.router.navigate(['/ecommerce/shop/buscar/articulos'], { queryParams: { seccion: seccion } }));
    } else {
      this.router.navigate(['/ecommerce/shop/buscar/articulos'], { queryParams: { seccion: seccion } });
    }
  }
}