<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"> Información de transferencia / depósito</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelar()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="input-group">
        <label class="col-md-5" style="font-weight: bold;">Número de operación:</label>
        <label>{{detalle.operacion}}</label>
    </div>

    <div class="input-group">
        <label class="col-md-5" style="font-weight: bold;">Importe</label>
        <label>{{detalle.tipmon}} {{detalle.importe | number: '.2-2'}}</label>
    </div>

    <div class="input-group">
        <label class="col-md-5" style="font-weight: bold;">Fecha:</label>
        <label>{{detalle.fecha | date: 'dd/MM/yyyy'}}</label>
    </div>
</div>