import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(array: any, field: string, order: string): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    array.sort((a: any, b: any) => {
      if(order === 'asc'){
        if (a[field] < b[field]) {
          return -1;
        } else if (a[field] > b[field]) {
          return 1;
        } else {
          return 0;
        }
      }else{
        if (b[field] < a[field]) {
          return -1;
        } else if (b[field] > a[field]) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    return array;
  }
}