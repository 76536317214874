<div class="modal-body">
    <button type="button" class="close" aria-label="Close" (click)="cancelar()">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="row">
        <div *ngIf="product.precio_venta > 0">
            <div *ngIf="product.precio_cliente === null">
                <div class="lable-block" *ngIf="product.desc_total != 0">
                    <span class="circulo" *ngIf="!viewDescuento">
                        <label class="text-transform-none">DSCTO.</label>
                    </span>

                    <span class="circulo2" *ngIf="viewDescuento">
                        <label class="text-transform-none">{{product.desc_total | number: '0.0-0'}}%<br>
                            <div style="font-size: 0.6rem !important; margin-top: -.4rem;">dscto.</div>
                        </label>
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="product.precio_venta_cliente > 0">
            <div *ngIf="product.precio_cliente != null">
                <div class="lable-block" *ngIf="product.desc_total_cliente != 0">
                    <span class="circulo" *ngIf="!viewDescuento">
                        <label class="text-transform-none">DSCTO.</label>
                    </span>

                    <span class="circulo2" *ngIf="viewDescuento">
                        <label class="text-transform-none">{{product.desc_total_cliente | number: '0.0-0'}}%<br>
                            <div style="font-size: 0.6rem !important; margin-top: -.4rem;">dscto.</div>
                        </label>
                    </span>
                </div>
            </div>
        </div>

        <div class="col-lg-6  col-xs-12">
            <div *ngIf="product.stock != 0" class="quick-view-img">
                <img [src]="product.url_imagen" alt="" class="img-fluid">
            </div>

            <div *ngIf="product.stock == 0" class="quick-view-img">
                <img [src]="product.url_imagen" alt="" class="img-fluid" style="opacity: 0.6;">
                <img src="./assets/images/agotado.png" class="img-fluid img2" alt="">
            </div>
        </div>
        <div class="col-lg-6 rtl-text">
            <div class="product-right">
                <h2>{{ product.descri }}</h2>
                <!-- PRECIO -->
                <div class="form-row" *ngIf="product.precio_venta > 0">
                    <div class="col-md-6">
                        <h3 *ngIf="product.precio_cliente == null">
                            {{product.moneda != null ? product.moneda : 'S/.'}}
                            {{product.precio_venta | number : '.2-2'}}
                        </h3>
                    </div>

                    <div class="col-md-6">
                        <div *ngIf="product.desc03 > 0 && product.desc04 > 0">
                            <del>
                                <span class="descuento">
                                    {{product.moneda != null ? product.moneda : 'S/.'}}
                                    {{product.precio_venta_desc3 | number : '.2-2'}}
                                </span>
                            </del>
                            <span class="descuento ml-2">
                                + {{ product.desc04 | number : '.0-0' }}% dscto.
                            </span>
                        </div>

                        <div *ngIf="product.desc02 > 0 && product.desc03 > 0">
                            <del>
                                <span class="descuento">
                                    {{product.moneda != null ? product.moneda : 'S/.'}}
                                    {{product.precio_venta_desc2 | number : '.2-2'}}
                                </span>
                            </del>
                            <span class="descuento ml-2">
                                +{{ product.desc03 | number : '.0-0' }}% dscto.
                            </span>
                        </div>

                        <div *ngIf="product.desc01 > 0 && product.desc02 > 0">
                            <del>
                                <span class="descuento">
                                    {{product.moneda != null ? product.moneda : 'S/.'}}
                                    {{product.precio_venta_desc1 | number : '.2-2'}}
                                </span>
                            </del>
                            <span class="descuento ml-2">
                                + {{ product.desc02 | number : '.0-0' }}% dscto.
                            </span>
                        </div>

                        <div *ngIf="product.precio > 0 && product.desc01 > 0">
                            <del>
                                <span class="descuento">
                                    {{product.moneda != null ? product.moneda : 'S/.'}}
                                    {{product.precio | number : '.2-2'}}
                                </span>
                            </del>
                            <span class="descuento ml-2">
                                {{ product.desc01 | number : '.0-0' }}% dscto.
                            </span>
                        </div>
                    </div>
                </div>

                <!-- PRECIO CLIENTE -->
                <div class="form-row" *ngIf="product.precio_venta_cliente > 0">
                    <div class="col-md-6">
                        <h4 *ngIf="product.precio_cliente != null">
                            {{product.moneda_cliente != null ? product.moneda_cliente : 'S/.'}}
                            {{product.precio_venta_cliente | number : '.2-2'}}
                        </h4>
                    </div>

                    <div class="col-md-6">
                        <div *ngIf="product.desc03_cliente > 0 && product.desc04_cliente > 0">
                            <del>
                                <span class="descuento">
                                    {{product.moneda_cliente != null ? product.moneda_cliente : 'S/.'}}
                                    {{product.precio_venta_cliente_desc3 | number : '.2-2'}}
                                </span>
                            </del>
                            <span class="descuento ml-2">
                                + {{ product.desc04_cliente | number : '.0-0' }}% dscto.
                            </span>
                        </div>

                        <div *ngIf="product.desc02_cliente > 0 && product.desc03_cliente > 0">
                            <del>
                                <span class="descuento">
                                    {{product.moneda_cliente != null ? product.moneda_cliente : 'S/.'}}
                                    {{product.precio_venta_cliente_desc2 | number : '.2-2'}}
                                </span>
                            </del>
                            <span class="descuento ml-2">
                                +{{ product.desc03_cliente | number : '.0-0' }}% dscto.
                            </span>
                        </div>

                        <div *ngIf="product.desc01_cliente > 0 && product.desc02_cliente > 0">
                            <del>
                                <span class="descuento">
                                    {{product.moneda_cliente != null ? product.moneda_cliente : 'S/.'}}
                                    {{product.precio_venta_cliente_desc1 | number : '.2-2'}}
                                </span>
                            </del>
                            <span class="descuento ml-2">
                                + {{ product.desc02_cliente | number : '.0-0' }}% dscto.
                            </span>
                        </div>

                        <div *ngIf="product.precio_cliente > 0 && product.desc01_cliente > 0">
                            <del>
                                <span class="descuento">
                                    {{product.moneda_cliente != null ? product.moneda_cliente : 'S/.'}}
                                    {{product.precio_cliente | number : '.2-2'}}
                                </span>
                            </del>
                            <span class="descuento ml-2">
                                {{ product.desc01_cliente | number : '.0-0' }}% dscto.
                            </span>
                        </div>
                    </div>
                </div>

                <h5 class="avalibility" *ngIf="product.stock > 0"><span style="color: seagreen">Disponible</span>
                </h5>
                <h5 class="avalibility" *ngIf="product.stock === 0"><span style="color: red;">No disponible</span>
                </h5>
                <div class="border-product">
                    <h6 class="product-title">Detalle</h6>
                    <p>{{product.subdetalle}}</p>
                </div>
                <div class="product-description border-product">
                    <h6 class="product-title">Cantidad</h6>
                    <div class="qty-box">
                        <div class="input-group">
                            <span class="input-group-prepend">
                                <button type="button" class="btn quantity-left-minus" data-type="minus"
                                    (click)="decrement()" [class.disabled]="counter === 1">
                                    <i class="ti-angle-left"></i>
                                </button>
                            </span>
                            <input type="text" name="quantity" class="form-control input-number" [(ngModel)]="counter">
                            <span class="input-group-prepend">
                                <button type="button" class="btn quantity-right-plus" data-type="plus"
                                    (click)="increment()" [class.disabled]="counter === product.stock">
                                    <i class="ti-angle-right"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="product-buttons">
                    <a [routerLink]="" [class.disabled]="counter > product.stock" (click)="addToCart(product)"
                        class="btn btn-solid">añadir</a>
                    <a [routerLink]="" (click)="onRouterViewDetalle(product)" class="btn btn-solid">ver
                        detalle</a>
                </div>
            </div>
        </div>
    </div>
</div>