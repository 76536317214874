import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Product } from "../../../classes/product";
import { ProductService } from '../../../../shared/services/product.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { CalcularMontosService } from '../../../../services/calcular-montos.service';
import { ArticuloService } from 'src/app/services/articulo.service';
import { ArticuloStockModel } from 'src/app/models/ArticuloStock.model';
import { basicAlert } from 'src/app/util/utils';
import { ApiRestServiceService } from 'src/app/services/api-rest-service.service';

@Component({
  selector: 'app-quick-view',
  templateUrl: './quick-view.component.html',
  styleUrls: ['./quick-view.component.scss']
})
export class QuickViewComponent implements OnInit {

  articuloStock = new ArticuloStockModel();
  counter: number = 1;
  product: any = {};
  sessionInfo: any = {};
  companiaInfo: any = {};
  id_cliente: number = null;
  cia_SHA1: string = '';
  viewDescuento: boolean = false;

  @Input() id_articulo: number = null;

  constructor(private modalactiveService: NgbActiveModal,
    private articuloService: ArticuloService,
    private calcularMontosService: CalcularMontosService,
    private router: Router,
    private apiRestServiceService: ApiRestServiceService,
    private productService: ProductService,
    private spinnerService: SpinnerService) {

    this.cia_SHA1 = localStorage.getItem("id_SHA");
    this.sessionInfo = JSON.parse(localStorage.getItem("sessionInfo"));
    this.companiaInfo = JSON.parse(localStorage.getItem('companiaInfo'));

    this.id_cliente = this.sessionInfo === null ? '' : this.sessionInfo.id;
  }

  ngOnInit(): void {
    this.articuloService.getArticuloByIdCiaSHA_1(this.id_articulo, this.id_cliente, this.companiaInfo.oracleIdCia, this.cia_SHA1).subscribe((response: any) => {
      console.log(response);
      this.product.tipinv = response.body.articulo.tipinv;
      this.product.id_articulo = this.id_articulo;
      this.product.codart = response.body.articulo.codart;
      this.product.codadd01 = response.body.articulo.codadd01;
      this.product.stock = response.body.articulo.stock == null ? 0 : response.body.articulo.stock;
      this.product.codadd02 = response.body.articulo.codadd02;
      this.product.code = response.body.articulo.code;
      this.product.descri = response.body.articulo.descri;
      this.product.subdetalle = response.body.articulo.subdetalle;
      this.product.precio = response.body.articulo.precio;
      this.product.url_imagen = response.body.articulo.url_imagen;
      this.product.moneda = response.body.articulo.moneda;
      this.product.desc01 = response.body.articulo.desc01;
      this.product.desc02 = response.body.articulo.desc02;
      this.product.desc03 = response.body.articulo.desc03;
      this.product.desc04 = response.body.articulo.desc04;
      this.product.precio = response.body.articulo.precio;
      this.product.desc_total = this.calcularMontosService.calcular_desc_total(response.body.articulo);
      this.product.precio_venta_desc1 = this.calcularMontosService.calcular_precio_total_desc1(response.body.articulo);
      this.product.precio_venta_desc2 = this.calcularMontosService.calcular_precio_total_desc2(response.body.articulo);
      this.product.precio_venta_desc3 = this.calcularMontosService.calcular_precio_total_desc3(response.body.articulo);
      this.product.precio_venta = this.calcularMontosService.calcular_precio_total_desc4(response.body.articulo);

      this.product.moneda_cliente = response.body.articulo.moneda_cliente;
      this.product.desc01_cliente = response.body.articulo.desc01_cliente;
      this.product.desc02_cliente = response.body.articulo.desc02_cliente;
      this.product.desc03_cliente = response.body.articulo.desc03_cliente;
      this.product.desc04_cliente = response.body.articulo.desc04_cliente;
      this.product.precio_cliente = response.body.articulo.precio_cliente;
      this.product.desc_total_cliente = this.calcularMontosService.calcular_desc_total_cliente(response.body.articulo);
      this.product.precio_venta_cliente_desc1 = this.calcularMontosService.calcular_precio_total_cliente_desc1(response.body.articulo);
      this.product.precio_venta_cliente_desc2 = this.calcularMontosService.calcular_precio_total_cliente_desc2(response.body.articulo);
      this.product.precio_venta_cliente_desc3 = this.calcularMontosService.calcular_precio_total_cliente_desc3(response.body.articulo);
      this.product.precio_venta_cliente = this.calcularMontosService.calcular_precio_total_cliente_desc4(response.body.articulo);
      // this.getStockArticulo(this.urlPath, this.product.tipinv, this.product.codart, -1, this.product.codadd01, this.product.codadd02, this.product.stock);

      if (this.product.precio_cliente === null) {
        if (this.product.desc01 > 0 && this.product.desc02 === 0 && this.product.desc03 === 0 && this.product.desc04 === 0 ||
          this.product.desc02 > 0 && this.product.desc01 === 0 && this.product.desc03 === 0 && this.product.desc04 === 0 ||
          this.product.desc03 > 0 && this.product.desc01 === 0 && this.product.desc02 === 0 && this.product.desc04 === 0 ||
          this.product.desc04 > 0 && this.product.desc01 === 0 && this.product.desc02 === 0 && this.product.desc03 === 0) {
          this.viewDescuento = true;
        } else {
          this.viewDescuento = false;
        }
      } else {
        if (this.product.desc01_cliente > 0 && this.product.desc02_cliente === 0 && this.product.desc03_cliente === 0 && this.product.desc04_cliente === 0 ||
          this.product.desc02_cliente > 0 && this.product.desc01_cliente === 0 && this.product.desc03_cliente === 0 && this.product.desc04_cliente === 0 ||
          this.product.desc03_cliente > 0 && this.product.desc01_cliente === 0 && this.product.desc02_cliente === 0 && this.product.desc04_cliente === 0 ||
          this.product.desc04_cliente > 0 && this.product.desc01_cliente === 0 && this.product.desc02_cliente === 0 && this.product.desc03_cliente === 0) {
          this.viewDescuento = true;
        } else {
          this.viewDescuento = false;
        }
      }
      this.spinnerService.activarLoading(false);
    },
      err => {
        console.log(err);
        this.cancelar();
        this.spinnerService.activarLoading(false);
      });
  }

  getStockArticulo(url: string, tokenERP: string, tipinv: number, codart: string, codalm: number, codadd01: string, codadd02: string, stock: number) {
    this.articuloService.getStockArticulo(url, tokenERP, tipinv, codart, codalm, codadd01, codadd02).subscribe((response: any) => {
      console.log(response)

      if (this.sessionInfo != null) {
        if (response.stock != stock) {
          this.actualizarStock(response.stock);
        } else {
          this.spinnerService.activarLoading(false);
        }
      } else {
        this.spinnerService.activarLoading(false);
      }
    },
      err => {
        console.log(err);
        this.spinnerService.activarLoading(false);
      });
  }

  actualizarStock(stock: number) {
    this.articuloStock.id = this.product.id_articulo;
    this.articuloStock.stock = stock;
    this.articuloService.updateArticuloStock(this.articuloStock).subscribe((response: any) => {
      console.log(response);
      this.product.stock = response.stock;
      // this.refreshListTabs = true;
      this.getListDashboard(this.cia_SHA1);
    }, err => {
      console.log(err);
      this.spinnerService.activarLoading(false);
    })
  }

  private getListDashboard(id_SHA1: string) {
    let orden: string = 'desc';

    this.apiRestServiceService.dashboardObtenerDashboard(id_SHA1, this.id_cliente, orden).subscribe((response: any) => {
      console.log(response.body)
      localStorage.setItem("dashboardDefault", JSON.stringify(response.body.secciones));
      if (this.sessionInfo != null) {
        localStorage.setItem("dashboardSession", JSON.stringify(response.body.secciones));
      }
    },
      err => {
        console.log(err);
        this.spinnerService.activarLoading(false);
      });
  }

  cancelar(): void {
    this.modalactiveService.dismiss('canceling');
  }

  public increment() {
    this.counter += 1;
  }

  public decrement() {
    if (this.counter > 1) {
      this.counter -= 1;
    }
  }

  // Add to cart
  async addToCart(product: any) {
    if (this.sessionInfo != null) {
      if (product.precio_venta != 0 || product.precio_venta_cliente != 0) {
        this.spinnerService.activarLoading(true)
        product.quantity = this.counter || 1;
        this.productService.addToCart(product, this.companiaInfo.oracleUrlErp, this.companiaInfo.oracleIdCia);
      } else {
        basicAlert('El artículo no tiene precio', 'warning', 'Aceptar');
      }
    }
    else {
      basicAlert('Para adicionar articulos al carrito de compras debe iniciar sesión', 'warning', 'Aceptar');
    }
  }

  onRouterViewDetalle(product: any) {
    this.cancelar();
    this.spinnerService.activarLoading(true);
    this.router.navigateByUrl(`/ecommerce/shop/left/detalle/articulo/${product.id_articulo}`);
  }
}