import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-oferta-inicio',
  templateUrl: './oferta-inicio.component.html',
  styleUrls: ['./oferta-inicio.component.scss']
})
export class OfertaInicioComponent implements OnInit {

  @Input() data: any
  imagen: string = "/assets/images/oferta_prueba.jpg";
  boton: string = "Comprar";

  constructor(private modalactiveService: NgbActiveModal,
    private spinnerService: SpinnerService,
    private router: Router) { }

  ngOnInit(): void {
    this.spinnerService.activarLoading(false);
  }

  cancelar(): void {
    this.modalactiveService.dismiss('canceling');
  }

  onClickOferta() {
    console.log(this.data.url.split("#")[1])
      let router: any = this.data.url.split("#")[1]
    this.cancelar();

    let link = this.data.url;
    console.log(link)
    if (link.includes('/#/ecommerce/')) {
      this.router.navigateByUrl(router);
    }else{
      window.open(this.data.url, '_blank')
    }
  }
}