import { Component, OnInit, Input } from '@angular/core';
import { NewProductSlider } from '../../../data/slider';
import { ArticuloService } from 'src/app/services/articulo.service';
import { Router } from '@angular/router';
import { SpinnerService } from 'src/app/services/spinner.service';

@Component({
  selector: 'app-product-box-vertical-slider',
  templateUrl: './product-box-vertical-slider.component.html',
  styleUrls: ['./product-box-vertical-slider.component.scss']
})
export class ProductBoxVerticalSliderComponent implements OnInit {

  @Input() title: string = 'New Product'; // Default
  @Input() type: string = 'fashion'; // Default Fashion
  @Input() products : any[] = [];

  public NewProductSliderConfig: any = NewProductSlider;

  constructor(public articuloService: ArticuloService,
    private spinnerService: SpinnerService,
    private router: Router) { }

  ngOnInit(): void { }

  redirectoDetalle(id: number) {
    let link = window.location.href;
    console.log(id)
    this.spinnerService.activarLoading(true);
    if (link.includes('/ecommerce/shop/left/detalle/articulo')) {
      this.router.navigateByUrl('/DummyComponent', { skipLocationChange: true }).then(() =>
        this.router.navigate([`/ecommerce/shop/left/detalle/articulo/${id}`]));
    } else {
      this.router.navigateByUrl(`/ecommerce/shop/left/detalle/articulo/${id}`);
    }
  }
}