<div class="navbar">
  <a href="javascript:void(0)" (click)="leftMenuToggle()">
    <div class="bar-style"><i class="fa fa-bars sidebar-bar" aria-hidden="true"></i></div>
  </a>
  <div id="mySidenav" class="sidenav" [class.openSide]="navServices?.leftMenuToggle">
    <a href="javascript:void(0)" class="sidebar-overlay" (click)="leftMenuToggle()"></a>
    <nav>
      <div (click)="leftMenuToggle()">
        <div class="sidebar-back text-left"><i class="fa fa-angle-left pr-2" aria-hidden="true"></i> Retornar
        </div>
      </div>
      <ul id="sub-menu" class="sidebar-menu">
        <li *ngFor="let lst of codigosPadre">
          <!-- Sub -->
          <a href="javascript:void(0)" (click)="toggletNavActive(lst)">
            {{lst.descri}}
            <span class="sub-arrow" *ngIf="lst.codigos"></span>
          </a>
          <!-- 2nd Level Menu -->
          <ul [class]="'mega-menu clothing-menu'" [class.opensub1]="lst.active" *ngIf="lst.codigos">
            <!-- Mega Menu Start-->
            <ng-container>
              <li>
                <div class="row m-0">
                  <div class="col-xl-4" *ngFor="let lst2 of lst.codigos | sort : 'descri' : 'asc'">
                    <div class="link-section">
                      <h5 class="cursor mb-3 colorview"
                        style="font-weight: bold !important; font-size: 1rem !important;"
                        (click)="onClickFiltroCodigos(lst2)">{{lst2.descri}}</h5>
                      <ul>
                        <li *ngFor="let lst3 of lst2.codigos | slice:0:9  | sort : 'descri' : 'asc'"
                          (click)="onClickFiltroCodigos(lst3)">
                          <!-- Link -->
                          <a class="cursor text-transform-none">
                            {{lst3.descri}}
                          </a>
                        </li>
                        <li (click)="onClickFiltroCodigos(lst2)" *ngIf="lst2.codigos.length > 5">
                          <a class="cursor text-transform-none"
                            style="font-weight: bold; font-size: 1rem; margin-top: 10px;">Ver todo</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ng-container>
            <!-- Mega Menu End-->
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</div>