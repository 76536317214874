import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConstants } from '../models/global-constants';
import { LibroReclamaciones } from '../models/LibroReclamaciones.model';
import { ClienteModel } from '../models/cliente.model';

@Injectable({
  providedIn: 'root'
})
export class ApiRestServiceService {

  constructor(private http: HttpClient) { }


  companyLogin_token_oracle(url: string, data: any) {
    return this.http.post(`${url}/usuario/auth-config`, data);
  }

  //List
  companygetInfoCompaniaByIdCiaSHA_1(cia_SHA1: string) {
    return this.http.get(`${GlobalConstants.urlService}/companias/obtener?cia=${cia_SHA1}`, { observe: 'response' });
  }

  companygetIdCiaByAlias(alias: string) {
    return this.http.get(`${GlobalConstants.urlService}/companias/alias?alias=${alias}`, { observe: 'response' });
  }

  companygetListCondiciones(cia_SHA1: string) {
    return this.http.get(`${GlobalConstants.urlService}/companias-condiciones/public/buscar?cia=${cia_SHA1}`, { observe: 'response' });
  }

  companygetByIdMoneda(id: number) {
    return this.http.get(`${GlobalConstants.urlService}/moneda/${id}`, { observe: 'response' })
  }

  companygetByTodayTipoCambio() {
    return this.http.get(`${GlobalConstants.urlService}/tcambio/obtener`);
  }

  companygetListMonedas() {
    return this.http.get(`${GlobalConstants.urlService}/moneda/buscar`, { observe: 'response' })
  }

  companygetListBancos() {
    return this.http.get(`${GlobalConstants.urlService}/bancos/buscar`, { observe: 'response' })
  }
  companygetListPayPal() {
    return this.http.get(`${GlobalConstants.urlService}/companias-paypp/buscar`, { observe: 'response' });
  }




  //************************************* */

  //Insertar Cliente
  public clienteBuscar(id: number, razon: string, limit: number, offset: number) {
    return this.http.get(`${GlobalConstants.urlService}/clientes/buscar?id=${id}&razon=${encodeURI(razon)}&limit=${limit}&offset=${offset}`, { observe: 'response' });
  }

  //Actualizar Cliente
  clienteActualizar(ClienteObject: ClienteModel) {
    return this.http.put(`${GlobalConstants.urlService}/clientes`, ClienteObject);
  }

  //Insertar Cliente
  clienteInsertar(ClienteObject: ClienteModel, cia: string) {
    return this.http.post(`${GlobalConstants.urlService}/clientes/registrate?cia=${cia}`, ClienteObject);
  }

  //Actualizar Cliente
  clienteActualizarClave(ClienteObject: ClienteModel) {
    return this.http.put(`${GlobalConstants.urlService}/clientes/cambiar-clave`, ClienteObject);
  }


  //******************************** */

  dashboardObtenerDashboard(id_ciaSHA1: string, id_cliente: number, orden: string) {
    return this.http.get(`${GlobalConstants.urlService}/dashboard/obtener?cia=${id_ciaSHA1}&cliente=${id_cliente}&orden=${orden}`, { observe: 'response' });
  }

  dashboardgetListDashboardPanel(id_clase_articulo: number, id_ciaSHA1: string) {
    return this.http.get(`${GlobalConstants.urlService}/dashboard-panels/public/buscar?id_clase_articulo=${id_clase_articulo}&cia=${id_ciaSHA1}`, { observe: 'response' });
  }

  dashboardgetListDashboardPanelV2(id_ciaSHA1: string) {
    return this.http.get(`${GlobalConstants.urlService}/dashboard-panels/public/buscar-v2?cia=${id_ciaSHA1}`, { observe: 'response' });
  }

  dashboardobtenerSeccionDetalle(id_seccion: number, id: number) {
    return this.http.get(`${GlobalConstants.urlService}/dashboard/seccion/${id_seccion}/detalle/${id}`, { observe: 'response' });
  }


  //************************ */

  //Listar
  tiendaList() {
    return this.http.get(`${GlobalConstants.urlService}/tiendas/buscar`, { observe: 'response' })
  }

  //Obtener 
  tiendaById(id: number) {
    return this.http.get(`${GlobalConstants.urlService}/tiendas/${id}`, { observe: 'response' })
  }

  //************************ */

  popupEcommercebuscar(cia_SHA1: string) {
    return this.http.get(`${GlobalConstants.urlService}/dashboard-popups/public/buscar?cia=${cia_SHA1}`, { observe: 'response' })
  }

  //***************************** */

  libroReclamacionesinsertar(Objeto: LibroReclamaciones) {
    return this.http.post(`${GlobalConstants.urlService}/libro-reclamaciones`, Objeto);
  }


  //******************************** */

  cpagoList() {
    return this.http.get(`${GlobalConstants.urlService}/cpago/buscar`, { observe: 'response' })
  }

  cpagoById(id: number) {
    return this.http.get(`${GlobalConstants.urlService}/cpago/${id}`, { observe: 'response' })
  }

  cpagoListERP(codclitsi: string) {
    return this.http.get(`${GlobalConstants.urlService}/cpago/validos/${codclitsi}`, { observe: 'response' })
  }

  //*************************** */
  paypalList() {
    return this.http.get(`${GlobalConstants.urlService}/companias-paypp/buscar`, { observe: 'response' });
  }

  //************************** */
  catalogoBuscar(cia_SHA1: string) {
    return this.http.get(`${GlobalConstants.urlService}/catalogos/public/buscar?cia=${cia_SHA1}`, { observe: 'response' });
  }

  catalogoDowloadCatalogo(id_pdf: number) {
    const fullUrl = `${GlobalConstants.urlService}/catalogos/public/pdf/${id_pdf}`;
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json'
    };
    return this.http.get(fullUrl, httpOptions);
  }

}
