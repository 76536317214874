<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"> Modificar contraseña</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancelar()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form>
        <div class="input-group mb-3">
            <label for="fname2" class="col-md-4">Contraseña anterior</label>
            <input type="text" class="form-control" [(ngModel)]="claveAnterior"
                name="claveAnterior" #claveId tabindex="100">
        </div>

        <div class="input-group mb-3">
            <label for="fname2" class="col-md-4">Contraseña nueva</label>
            <input type="text" class="form-control" [(ngModel)]="nuevaClave" name="nuevaClave"
                #claveNueva tabindex="101">
        </div>

        <div class="input-group mb-3">
            <label for="fname2" style="margin-left: 1rem;">Confirmar contraseña</label>
            <input type="text" class="form-control ml-1" [(ngModel)]="confirmarClave"
                name="confirmarClave" #confirmar tabindex="102">
        </div>
    </form>

    <div class="input-group d-flex justify-content-end">
        <button type="button" class="btn btn-solid mr-2" style="background: #6d7e87; border: solid #6d7e87;" tabindex="103" (click)="guardar()">Guardar</button>
        <button type="button" class="btn btn-solid" style="background: red; border: solid red;" tabindex="104" (keydown.tab)="onNexFocus()"
            (click)="cancelar()">Cancelar</button>
    </div>
</div>